import { useEffect, useState } from 'react';
import { RoleOrganization, useGetMyOrganizationRolesQuery } from '../../generated/gql/types';

function useInternalDataMarketOrganization(): string | undefined {
  const [orgId, setOrgId] = useState<string | undefined>(undefined);
  const { data: organizationRoles, loading: organizationRolesLoading } = useGetMyOrganizationRolesQuery();

  useEffect(() => {
    if (organizationRoles) {
      const organizationRole = organizationRoles.myOrganizationRoles
        .find((item) => (
          item.organizationRoles.includes(RoleOrganization.OrgDataMarketUser)
          || item.organizationRoles.includes(RoleOrganization.OrgDataMarketSubscriptionManager)
        ));

      setOrgId(organizationRole?.organization.orgId);
    }
  }, [organizationRoles, organizationRolesLoading]);

  return orgId;
}

export default useInternalDataMarketOrganization;
