import React, { Fragment } from 'react';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function TagList({ tags, limit }: TagListProps) {
  if (tags.length <= 0) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return (<></>);
  }
  const listLimit = limit ?? 100;

  return (
    <div className="inline-flex gap-x-2 items-center w-full text-sm break-inside-auto flex-wrap">
      {tags.slice(0, listLimit).map((tag, index) => (
        <Fragment key={`taglist-${tag}-${crypto.randomUUID()}`}>
          <div className="text-gray-600 whitespace-nowrap font-medium">{tag}</div>
          {index !== (tags.slice(0, listLimit).length - 1) && (
            <FontAwesomeIcon
              icon={icon({ name: 'circle', family: 'classic', style: 'solid' })}
              size="2xs"
              className="text-gray-300"
            />
          )}
        </Fragment>
      ))}

      {tags.length > (listLimit) && (
        <div className="text-gray-400">
          +
          {tags.length - listLimit}
        </div>
      )}
    </div>
  );
}

type TagListProps = {
  tags: string[],
  limit?: number
};

export default TagList;
