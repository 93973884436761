import React from 'react';
import {
  Pie, PieChart,
} from 'recharts';
import { useParams } from 'react-router-dom';
import {
  DataProductPlanSubscription,
  useGetSubscriptionMeteringQuery,
} from '../../generated/gql/types';
import { WeCityOrganizationIdHeader } from '../../Global';

function SubscriptionUsageDonutChart({ subscription, maxCalls }: BadgeProps) {
  const params = useParams();
  const { data: apiData, loading } = useGetSubscriptionMeteringQuery({
    fetchPolicy: 'network-only',
    context: {
      clientName: 'cube',
      headers: {
        [WeCityOrganizationIdHeader]: params.orgId,
      },
    },
    variables: {
      where: {
        key_id: {
          in: subscription.keys.map((k) => k.id),
        },
        day: {
          inDateRange: ['Last 30 days'],
        },
      },
    },
  });

  function calculateDataLeft() {
    const total = (apiData?.cube.map((result) => result.data_market_metering.sum_calls ?? 0) ?? [0]).reduce((acc, currentValue) => acc + currentValue, 0);
    return [
      {
        name: 'Used',
        value: total,
        fill: '#1F2A37',
      },
      {
        name: 'Left',
        value: Math.max(maxCalls - total, 0),
        fill: '#E5E7EB',
      },
    ];
  }

  const defaultData = [
    {
      name: 'Used',
      value: 0,
      fill: '#1F2A37',
    },
    {
      name: 'Left',
      value: maxCalls,
      fill: '#E5E7EB',
    },
  ];

  function renderPieChart() {
    const data = calculateDataLeft();
    const percentage = Math.round(Math.abs((data[0].value / maxCalls) * 100));
    return (
      <PieChart width={260} height={260}>
        <Pie
          startAngle={90}
          endAngle={-270}
          dataKey="value"
          data={data}
          cx={124}
          cy={130}
          innerRadius={95}
          outerRadius={115}
          stroke="none"
        />
        <text x={135} y={120} dy={8} textAnchor="middle" fontSize={40} fontWeight="bold" fill="#111928">
          {percentage}
          %
        </text>
        <text x={130} y={145} dy={8} textAnchor="middle" fill="#6B7280">
          {data[0].value}
          {' '}
          /
          {' '}
          {maxCalls}
          {' '}
          gebruikt
        </text>

      </PieChart>
    );
  }

  return (
    <div>
      {loading ? (
        <PieChart width={260} height={260}>
          <Pie
            startAngle={90}
            endAngle={-270}
            dataKey="value"
            data={defaultData}
            cx={124}
            cy={130}
            innerRadius={95}
            outerRadius={115}
            stroke="none"
          />
          <text x={150} y={150} dy={8} textAnchor="middle">
            0 /
            {' '}
            {maxCalls}
            {' '}
            gebruikt
          </text>
        </PieChart>
      ) : (
        renderPieChart()
      )}
    </div>
  );
}

interface BadgeProps {
  maxCalls: number
  subscription: DataProductPlanSubscription
}

export default SubscriptionUsageDonutChart;
