import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useOidcIdToken } from '@axa-fr/react-oidc';
import useDefaultDataMarketOrganization from '../hooks/useDefaultDataMarketOrganization';

function UseCaseSubmissionCard() {
  const { idTokenPayload } = useOidcIdToken();
  const defaultOrgId = useDefaultDataMarketOrganization();
  const params = useParams();

  function createSupportUrl(): string {
    const urlParams = new URLSearchParams({
      email: idTokenPayload.email ?? '',
      wecity_organization_id: params?.orgId ? params.orgId : (defaultOrgId ?? 'onbekend'),
    });

    return `https://share-eu1.hsforms.com/1kWs1QG5XSh2kXtUvvyNrmQ2borbx?${urlParams.toString()}`;
  }

  if (!idTokenPayload) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <></>;
  }

  return (
    <div className="flex bg-white rounded-lg max-w-sm break-inside-avoid">
      <div className="p-4">
        <div className="text-base text-gray-900 ">
          Heeft u een praktijkvoorbeeld?
        </div>
        <div className="text-gray-900 font-bold text-base">
          {}
          <Link target="_blank" to={createSupportUrl()}>
            Deel het met ons
            {' '}
            <FontAwesomeIcon
              icon={icon({ name: 'external-link', family: 'classic', style: 'regular' })}
            />
          </Link>
        </div>
      </div>
    </div>
  );
}

export default UseCaseSubmissionCard;
