import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Label } from 'flowbite-react';
import React, { useState } from 'react';

export interface IFormFieldProps {
  fieldName: string;
  label: string;
  children: React.ReactNode;
  appendAsterix?: boolean;
  extraInfo?: string;
  hint?: string;
  instruction?: string;
  error?: string;
}

function FormField({
  fieldName,
  label,
  children,
  appendAsterix,
  extraInfo,
  hint,
  instruction,
  error,
}: IFormFieldProps) {
  const [infoOpen, setInfoOpen] = useState(false);

  return (
    <div className="flex flex-col gap-1 grow">
      <div>
        <div>
          <Label htmlFor={fieldName} value={label} className="font-bold text-lg" />
          {appendAsterix && ' * '}
          <span className="ml-5 font-bold text-error">{error}</span>
        </div>
        {extraInfo && (
          <button type="button" className="text-gray-600" onClick={() => setInfoOpen(!infoOpen)}>
            {!infoOpen ? 'Meer informatie' : 'Minder informatie'}
            <FontAwesomeIcon
              className="my-auto ml-2"
              icon={!infoOpen ? icon({ name: 'chevron-down' }) : icon({ name: 'chevron-up' })}
              color="gray"
            />
          </button>
        )}
        {
          infoOpen && (
            <div className="border-l border-l-2 border-gray-400 ml-2 mb-2 mt-1 px-2 italic text-gray-500">
              {extraInfo}
            </div>
          )
        }
      </div>
      <div>
        <span className="text-gray text-sm">{hint}</span>
        {children}
        <span className="text-sm text-red mt-1">{instruction}</span>
      </div>
    </div>
  );
}

export default FormField;
