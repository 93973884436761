import React from 'react';
import ImageCard from './ImageCard';

import {
  ProductVisibility,
} from '../../Global';
import useProductFilters, { ProductLocation, ProductTheme } from '../hooks/useProductFilters';
import { DataProductLocationType } from '../../generated/gql/types';

interface ILocationSectionProps {
  setFilter: (filter: string, value: ProductLocation | ProductTheme | ProductVisibility) => void;
}

function ImageCardFilters({ setFilter }: ILocationSectionProps) {
  const productFilters = useProductFilters();

  return (
    <div className="bg-white p-10 mb-10">
      <div className="max-w-screen-xl grow mx-auto ">
        <ul className="flex overflow-x-scroll w-screen-xl gap-6 py-6">
          {
            productFilters?.cities.filter((l) => l.type === DataProductLocationType.City).filter((location) => location.imagePath !== undefined).map((location) => (
              location.imagePath && (
                <li key={`${location.label}_${location.type}`}>
                  <ImageCard imgSrc={location.imagePath} subject={location.label} onButtonClick={() => setFilter('location', location)} />
                </li>
              )
            ))
          }
        </ul>
        <ul className="flex overflow-x-scroll w-screen-xl gap-6 py-6">
          {
            productFilters?.themes.filter((theme) => theme.imagePath !== undefined).map((theme) => (
              theme.imagePath && (
                <li key={`${theme.label}_${theme.type}`}>
                  <ImageCard
                    imgSrc={theme.imagePath}
                    subject={theme.label}
                    onButtonClick={() => setFilter('theme', theme)}
                  />
                </li>
              )
            ))
          }
        </ul>
      </div>
    </div>
  );
}

export default ImageCardFilters;
