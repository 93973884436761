import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Checkbox, Label } from 'flowbite-react';
import { useForm } from 'react-hook-form';
import { WeCityOrganizationIdHeader } from '../../../Global';
import { useGetMyProductQuery } from '../../../generated/gql/types';
import {
  MyOfferingsOverviewRoute,
  OfferWizardGetStartedRoute,
} from '../../Routes';
import InfoButton from '../../library/InfoButton';
import { syncOfferWithLocalStorage } from '../../../Helpers';

function TellUs() {
  const params = useParams();
  const { data } = useGetMyProductQuery({
    fetchPolicy: 'network-only',
    context: {
      headers: {
        [WeCityOrganizationIdHeader]: params.orgId,
      },
    },
    variables: {
      id: params.productId ?? '',
    },
  });

  const {
    register,
  } = useForm<TellUseMoreForm>({
    defaultValues: {
      isStandardized: false,
      isValueForUse: false,
      isOdpsCompatible: false,
      wantsMultipleProducts: false,
    },
  });

  useEffect(() => {
    if (data?.dataMarket?.userProduct && params.productId) {
      syncOfferWithLocalStorage(params.productId, data?.dataMarket.userProduct.id);
    }
  }, [data]);

  return (
    <div
      className="mx-auto relative max-w-screen-xl flex items-center justify-center w-full flex-col p-10 bg-white space-y-2 my-5"
    >
      <div className="self-start">
        <Link to={MyOfferingsOverviewRoute(params.orgId)}>
          <div className="flex gap-x-2 items-center">
            <FontAwesomeIcon
              icon={icon({ name: 'angle-left', family: 'classic', style: 'solid' })}
              size="lg"
              className="text-primary-500"
            />
            <div className="text-primary-500">Terug naar mijn aanbod</div>
          </div>
        </Link>
      </div>
      <div className="text-gray-900 text-3xl font-bold">Kan je ons wat meer vertellen over je product?</div>

      <div>
        <div className="flex gap-x-2 items-center">
          <Checkbox
            id="isStandardized"
            {...register('isStandardized', { required: true })}
          />
          <Label htmlFor="isStandardized" className="flex">
            Mijn product is gestandaardiseerd voor structureringsdoeleinden.
          </Label>
        </div>
        <div className="flex gap-x-2 items-center">
          <Checkbox
            id="isValueForUse"
            {...register('isValueForUse', { required: true })}
          />
          <Label htmlFor="isValueForUse" className="flex">
            Mijn data is verbonden met semantische modellen voor waarde van gebruik en interoperabiliteit.
          </Label>
        </div>
        <div className="flex gap-x-2 items-center">
          <Checkbox
            id="isOdpsCompatible"
            {...register('isOdpsCompatible', { required: true })}
          />
          <Label htmlFor="isOdpsCompatible" className="flex">
            Mijn data is compatibel voor uitwisseling (DCAT/ODPS/anders).
          </Label>
        </div>
        <div className="flex gap-x-2 items-center">
          <Checkbox
            id="wantsMultipleProducts"
            {...register('wantsMultipleProducts', { required: false })}
          />
          <Label htmlFor="wantsMultipleProducts" className="flex">
            Ik wil een nieuw dataproduct aanbieden dat is opgebouwd uit meer dan één gegevensbron.
          </Label>
        </div>
      </div>

      <Link to={OfferWizardGetStartedRoute(params.orgId, params.productId)}>
        <InfoButton>
          <div className="text-xs lg:text-sm font-medium w-full">Volgende</div>
          <FontAwesomeIcon
            icon={icon({ name: 'arrow-right', family: 'classic', style: 'solid' })}
            size="lg"
            className="text-white"
          />
        </InfoButton>
      </Link>
    </div>
  );
}

type TellUseMoreForm = {
  isStandardized: boolean,
  isValueForUse: boolean,
  isOdpsCompatible: boolean,
  wantsMultipleProducts: boolean
};

export default TellUs;
