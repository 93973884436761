import React from 'react';
import { useOidc, useOidcIdToken } from '@axa-fr/react-oidc';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Button as FlowBiteButton } from 'flowbite-react/lib/esm/components/Button/Button';
import { CatalogRoute } from '../Routes';

function UserProfile() {
  const { login, logout } = useOidc();
  const { idTokenPayload } = useOidcIdToken();

  if (!idTokenPayload) {
    return (
      <FlowBiteButton size="sm" color="info" onClick={() => login(CatalogRoute())}>
        <div className="w-full justify-center items-center gap-2 inline-flex">
          Inloggen
        </div>
      </FlowBiteButton>
    );
  }

  return (
    <>
      <div className="inline-flex gap-x-2 items-center">
        <FontAwesomeIcon
          icon={icon({ name: 'user-circle', family: 'classic', style: 'solid' })}
          size="2x"
          className="text-primary-700"
        />
        <p className="text-primary-800 hidden md:inline-block truncate w-[160px]">
          {idTokenPayload?.firstname ?? 'onbekend'}
          {' '}
          {idTokenPayload?.lastname ?? 'onbekend'}
        </p>
      </div>
      <button
        type="button"
        className="text-gray-500 font-bold text-sm items-center hidden md:flex h-[36px]"
        onClick={() => logout(CatalogRoute())}
      >
        Uitloggen
      </button>
    </>
  );
}

export default UserProfile;
