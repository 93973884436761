import { useOidcIdToken } from '@axa-fr/react-oidc';
import useInternalDataMarketOrganization from './useInternalDataMarketOrganization';

function useDefaultDataMarketOrganization(): string | undefined {
  const { idTokenPayload } = useOidcIdToken();
  if (idTokenPayload) {
    return useInternalDataMarketOrganization();
  }

  return undefined;
}

export default useDefaultDataMarketOrganization;
