import React, { ChangeEvent, useEffect, useState } from 'react';
import {
  Button,
  Checkbox, Label, Select, Textarea, TextInput,
} from 'flowbite-react';
// import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Link, useNavigate, useParams } from 'react-router-dom';
import WizardStep from '../../library/form/WizardStep';
import { IWizardForm } from '../../../productForms';
import FormField from '../../library/form/FormField';
import {
  DataProductDataType, MutationPricePlanInput, MutationPricePlanUpdateInput, PricePlan_PricePlanBasedOnType_MutationInput, PricePlan_PricePlanType, PricePlanUpdate_PricePlanBasedOnType_MutationInput, useCreatePricePlanMutation, useGetPricePlanQuery,
  useUpdatePricePlanMutation,
  ProductUpdate_PricePlansRelationshipInput,
  useUpdateGenericProductMutation,
  useGetGenericProductQuery,
  PricePlan_RecurringPricePer,
  PricePlan_MinimumSubscriptionTime,
} from '../../../generated/gql/types';
import { GenericOfferWizardPricePlansroute, PricePlansOverviewRoute } from '../../Routes';
import { WeCityOrganizationIdHeader } from '../../../Global';

const PaymentPlanForm: IWizardForm = {
  fieldCount: 1,
  lang: 'nl',
  title: 'Prijsplannen',
  steps: [
    {
      name: 'Prijsplan maken',
      description: 'Maak een Priceplan om aan producten te koppelen.',
    },
  ],
};

function PricePlan() {
  const navigate = useNavigate();
  const params = useParams();
  const context = {
    clientName: 'cms',
    fetchPolicy: 'network-only',
    headers: {
      [WeCityOrganizationIdHeader]: params.orgId,
    },
  };
  const [createPricePlan, { loading: createLoading }] = useCreatePricePlanMutation({ context });
  const [updatePricePlan, { loading: updateLoading }] = useUpdatePricePlanMutation({ context });
  const [updateProduct] = useUpdateGenericProductMutation({ context });

  const { data: productData } = useGetGenericProductQuery({
    context,
    variables: {
      id: params.productId ?? '',
    },
  });
  const { data: cmsPricePlan, loading: cmsPricePlanLoading } = useGetPricePlanQuery({ context, variables: { id: params.pricePlanId ?? '' } });

  const [selectedType, setSelectedType] = useState<string>(PricePlan_PricePlanType.OneTime);
  const [dmiCommon, setDmiCommon] = useState<DataProductDataType>(DataProductDataType.Common);

  const toggleDmiCommon = (e: HTMLInputElement) => {
    if (e.checked) setDmiCommon(DataProductDataType.Common);
    else setDmiCommon(DataProductDataType.Personal);
  };

  const {
    register,
    handleSubmit,
    watch,
  } = useForm<Fields>();

  const pricePlanType = watch('pricePlanType', undefined);

  const previousPage = () => {
    if (params.productId) {
      return GenericOfferWizardPricePlansroute(params.orgId, params.productId, params.productType);
    } return PricePlansOverviewRoute(params.orgId);
  };

  useEffect(() => {
    setSelectedType(pricePlanType);
  }, [pricePlanType]);

  const onSubmit = handleSubmit((fields) => {
    const submitData = {
      ...fields,
      pricePlanType,
      amountOfItemsOneTime: 0,
      allItemsOneTime: true,
      amountOfItemsRecurring: 0,
      allItemsRecurring: true,
      dmiCommon: dmiCommon === DataProductDataType.Common,
      reviewState: 'ACCEPTED',
    };
    if (cmsPricePlan?.PricePlan?.id && !updateLoading) {
      const updateData: MutationPricePlanUpdateInput = {
        ...submitData,
        pricePlanBasedOnType: PricePlanUpdate_PricePlanBasedOnType_MutationInput.AllProducts,
      } as MutationPricePlanUpdateInput;
      if (cmsPricePlan?.PricePlan?.id && !createLoading) {
        updatePricePlan({ variables: { id: cmsPricePlan?.PricePlan?.id, data: updateData } })
          .then((_) => { navigate(previousPage()); });
      }
    } else
      if (!cmsPricePlan?.PricePlan?.id && !createLoading) {
        const createData: MutationPricePlanInput = {
          ...submitData,
          id: params.pricePlanId,
          pricePlanBasedOnType: PricePlan_PricePlanBasedOnType_MutationInput.AllProducts,
        } as MutationPricePlanInput;
        createPricePlan({ variables: { data: createData } })
          .then((res) => {
            if (res.data?.createPricePlan?.id && productData?.Product && productData?.Product.id) {
              const existingPricePlans = productData.Product.pricePlans?.map(((plan) => plan.value?.id));
              const pricePlanList = [...existingPricePlans || [], res.data.createPricePlan.id];
              updateProduct({
                variables: {
                  id: productData?.Product?.id,
                  data: {
                    pricePlans: pricePlanList.map((pricePlanId) => ({ value: pricePlanId, relationTo: 'price_plans' } as ProductUpdate_PricePlansRelationshipInput)),
                  },
                },
              });
            }
          }).then((_) => { navigate(previousPage()); });
      }
  });

  return (
    <WizardStep form={PaymentPlanForm} currentStep={0} onSubmit={onSubmit}>
      {!cmsPricePlanLoading && (
        <>
          <div className="bg-gray-100 rounded-lg p-5 shadow-lg">
            <FormField fieldName="" label="Betalingsopties">
              <Select id="visibility" color="info" className="productTypemax-w-screen-md" required value={pricePlanType} {...register('pricePlanType', { value: cmsPricePlan?.PricePlan?.pricePlanType || 'one_time' })}>
                <option value={PricePlan_PricePlanType.Subscription}>
                  Abonnement
                </option>
                <option value={PricePlan_PricePlanType.OneTime}>
                  Eenmalig
                </option>
                <option value={PricePlan_PricePlanType.OneTimeSubscription}>
                  Abonnement met een eenmalige betaling
                </option>
              </Select>
            </FormField>
            {(selectedType === PricePlan_PricePlanType.Subscription || selectedType === PricePlan_PricePlanType.OneTimeSubscription) && (
              <>
                <div className="bg-gray-300 w-[90%] h-[2px] my-5 mx-auto" />
                <div className="flex flex-col gap-3">
                  <FormField fieldName="" label="Periodieke betaling">
                    <div className="flex">
                      <div className="flex gap-2">
                        <div className="my-auto">De abonnementsprijs is</div>
                        <TextInput type="number" id="price-recurring-amount" placeholder="bedrag" {...register('priceRecurring', { value: cmsPricePlan?.PricePlan?.priceRecurring || undefined, required: true, valueAsNumber: true })} />
                        <div className="my-auto">voor</div>
                        <Select disabled id="item-amount-recurring" placeholder="Alle producten">
                          <option value="all">Alle producten</option>
                        </Select>
                        <div className="my-auto">per</div>
                        <Select id="priceRecurring-price-per" {...register('recurringPricePer', { value: cmsPricePlan?.PricePlan?.recurringPricePer || undefined, required: true })}>
                          <option value={PricePlan_RecurringPricePer.Month}>
                            Maand
                          </option>
                          <option value={PricePlan_RecurringPricePer.Quarter}>
                            Kwartaal
                          </option>
                          <option value={PricePlan_RecurringPricePer.HalfYear}>
                            Half jaar
                          </option>
                          <option value={PricePlan_RecurringPricePer.Year}>
                            Jaar
                          </option>
                        </Select>
                      </div>
                    </div>
                  </FormField>
                  <div className="flex">
                    <FormField fieldName="Minimale contractduur" label="Minimale contractduur">
                      <span>
                        Dit prijsplan moet worden afgenomen voor minimaal één
                        <Select className="inline-flex ml-2" {...register('minimumSubscriptionTime', { value: cmsPricePlan?.PricePlan?.minimumSubscriptionTime || undefined, required: true })}>
                          <option value={PricePlan_MinimumSubscriptionTime.Month}>
                            Maand
                          </option>
                          <option value={PricePlan_MinimumSubscriptionTime.Quarter}>
                            Kwartaal
                          </option>
                          <option value={PricePlan_MinimumSubscriptionTime.HalfYear}>
                            Half jaar
                          </option>
                          <option value={PricePlan_MinimumSubscriptionTime.Year}>
                            Jaar
                          </option>
                        </Select>
                      </span>
                    </FormField>
                    <FormField fieldName="" label="Betalingsperiode">
                      <span>
                        De betaling vindt plaats per
                        <Select className="inline-flex ml-2" {...register('paymentPeriod', { value: cmsPricePlan?.PricePlan?.paymentPeriod || undefined, required: true })}>
                          <option value={PricePlan_MinimumSubscriptionTime.Month}>
                            Maand
                          </option>
                          <option value={PricePlan_MinimumSubscriptionTime.Quarter}>
                            Kwartaal
                          </option>
                          <option value={PricePlan_MinimumSubscriptionTime.HalfYear}>
                            Half jaar
                          </option>
                          <option value={PricePlan_MinimumSubscriptionTime.Year}>
                            Jaar
                          </option>
                        </Select>
                      </span>
                    </FormField>
                  </div>
                </div>
              </>
            )}

            {(selectedType === PricePlan_PricePlanType.OneTime || selectedType === PricePlan_PricePlanType.OneTimeSubscription) && (
              <>
                <div className="bg-gray-300 w-[90%] h-[2px] my-5 mx-auto" />
                <FormField fieldName="" label="Eenmalige betaling">
                  <div className="flex">
                    <div className="flex gap-2">
                      <div className="my-auto">De eenmalige prijs is </div>
                      <TextInput
                        type="number"
                        id="price-one-time"
                        placeholder="bedrag"
                        {...register('priceOneTime', { value: cmsPricePlan?.PricePlan?.priceOneTime || undefined, required: true, valueAsNumber: true })}
                      />
                      <div className="my-auto">voor</div>
                      <Select disabled id="item-amount-onetime" placeholder="Alle producten">
                        <option value="week">Alle producten</option>
                      </Select>
                    </div>
                  </div>
                </FormField>
              </>
            )}
          </div>
          <FormField fieldName="license" label="Licentie">
            <div className="flex gap-2">
              <Checkbox
                {...register('dmiCommon', { value: cmsPricePlan?.PricePlan?.dmiCommon })}
                defaultChecked={dmiCommon === DataProductDataType.Common}
                value="commons"
                onChange={(e: ChangeEvent<HTMLInputElement>) => toggleDmiCommon(e.currentTarget)}
              />
              <Label value="DMI Common" />

            </div>
          </FormField>
          <FormField fieldName="name" label="Kies een naam/titel van je Price Plan" instruction="Max. 50 karakters" appendAsterix>
            <TextInput
              {...register('name', { value: cmsPricePlan?.PricePlan?.name, required: true, maxLength: 50 })}
            />
          </FormField>
          <FormField fieldName="description" label="Geef een korte beschrijving van je prijsplan" instruction="Max. 1000 karakters" appendAsterix>
            <Textarea
              rows={8}
              id="description"
              placeholder="bijv. Gemeentelijke bereikbaarheid van publieksvoorzieningen..."
              required
              maxLength={1000}
              {...register('description', { value: cmsPricePlan?.PricePlan?.description ?? '' })}
            />
          </FormField>
          <div />
        </>
      )}
      <div className="py-5 flex">
        <Link to={previousPage()}>
          <Button size="lg" color="light">
            <div className="w-full justify-center items-center gap-2 inline-flex">
              <div className="text-xs lg:text-sm font-medium w-full">Terug</div>
            </div>
          </Button>
        </Link>
        <Button
          type="submit"
          className="w-[333px] ml-auto rounded-lg text-center font-medium"
        >
          <div className="px-0.5 py-0.5 text-lg">{!cmsPricePlan?.PricePlan?.id ? 'Plan Aanmaken' : 'Plan Wijzigen'}</div>
        </Button>
      </div>
    </WizardStep>
  );
}

type Fields = {
  id: string;
  name: string;
  amountOfItemsOneTime?: number | 'all';
  amountOfItemsRecurring?: number | 'all';
  customDefinition: string;
  priceOneTime: number;
  priceRecurring: number;
  description: string;
  pricePlanId: string;
  dmiCommon: boolean;
  pricePlanType: string
  pricePlanBasedOnType: string;
  recurringPricePer: string;
  paymentPeriod: string;
  minimumSubscriptionTime: string
};

export default PricePlan;
