import React, { useEffect, useState } from 'react';
import { Button, Dropdown } from 'flowbite-react';
import { DropdownItem } from 'flowbite-react/lib/esm/components/Dropdown/DropdownItem';
import { useParams } from 'react-router-dom';
import {
  PricePlan, ProductUpdate_PricePlansRelationshipInput, useGetPricePlansForOrgQuery, useUpdateGenericProductMutation,
} from '../../generated/gql/types';
import { UnifiedProduct } from '../../Helpers';
import { WeCityOrganizationIdHeader } from '../../Global';

export interface LinkPriceplanProps {
  products: UnifiedProduct[];
  refetch: () => void;
}

export default function LinkPriceplan({ products, refetch }: LinkPriceplanProps) {
  const [selectedPricePlan, setSelectedPricePlan] = useState<PricePlan | undefined>(undefined);
  const [selectedProduct, setSelectedProduct] = useState<UnifiedProduct | undefined>(undefined);
  const params = useParams();

  useEffect(() => {
    if (products.length === 1) {
      setSelectedProduct(products[0]);
    }
  }, [products]);

  const { data: cmsPricePlans } = useGetPricePlansForOrgQuery({
    context: {
      clientName: 'cms',
      fetchPolicy: 'network-only',
      headers: {
        [WeCityOrganizationIdHeader]: params.orgId,
      },
    },
    variables: {
      orgId: params.orgId ?? '',
    },
  });

  const [updateGenericProduct] = useUpdateGenericProductMutation({
    context: {
      clientName: 'cms',
      fetchPolicy: 'network-only',
      headers: {
        [WeCityOrganizationIdHeader]: params.orgId,
      },
    },
  });

  const onButtonClick = () => {
    if (!selectedProduct || !selectedPricePlan) {
      return;
    }
    let newPlanIds = selectedProduct.pricePlanIds || [];
    if (!newPlanIds?.includes(selectedPricePlan.id)) {
      newPlanIds = [...newPlanIds, selectedPricePlan.id];
    }
    updateGenericProduct({
      variables: {
        id: selectedProduct?.id!,
        data: {
          pricePlans: newPlanIds.map((pricePlanId) => ({ value: pricePlanId, relationTo: 'price_plans' } as ProductUpdate_PricePlansRelationshipInput)),
        },
      },
    }).then(() => {
      setSelectedPricePlan(undefined);
      setSelectedProduct(undefined);
      refetch();
    });
  };

  return (
    <div className="bg-white rounded-lg p-4 flex-col flex gap-3 border border-2 border-blue-400 shadow-md">
      <div className="text-xl font-bold text-gray-500">Koppel een bestaand prijsplan</div>
      <div className="my-auto flex flex-col gap-6 py-5">
        <div className="text-sm italic">Momenteel kunnen flexibele prijsplannen alleen gekoppeld worden aan Concultancy, Hardware of Applicaties</div>
        <div className="flex w-full">
          <div className="font-bold text-gray-400">Product:</div>

          <div className="ml-auto">
            <Dropdown disabled={products.length < 1} id="products-link" label={!selectedProduct ? 'Product' : selectedProduct.name} inline className="ml-auto">
              {products.length > 1 ? products.map((product) => (
                <DropdownItem key={product?.id} value={product?.id!} onClick={() => setSelectedProduct(product)}>{product.name}</DropdownItem>
              )) : <DropdownItem>{products[0]?.name}</DropdownItem>}
            </Dropdown>
          </div>
        </div>

        <div className="flex w-full">
          <div className="font-bold text-gray-400">Prijsplan:</div>
          <div className="ml-auto">
            <Dropdown id="priceplans-link" label={!selectedPricePlan ? 'Prijsplan' : selectedPricePlan.name} inline>
              {cmsPricePlans?.PricePlans?.docs?.map((priceplan) => {
                if (priceplan && !selectedProduct?.pricePlanIds?.includes(priceplan.id)) {
                  return <DropdownItem key={priceplan?.id} onClick={() => setSelectedPricePlan(priceplan as PricePlan)}>{priceplan?.name}</DropdownItem>;
                }
                return null;
              })}
            </Dropdown>
          </div>
        </div>
      </div>
      <div className="w-full flex">
        <Button color="gray" onClick={onButtonClick} className="ml-auto font-bold text-gray-800">Maak koppeling</Button>
      </div>
    </div>
  );
}
