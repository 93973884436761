import React from 'react';
import {
  DeepPartial, FlowbiteTabTheme, Table, Tabs,
} from 'flowbite-react';
import {
  FaArchive, FaBell, FaUnlock,
} from 'react-icons/fa';
import { Button as FlowBiteButton } from 'flowbite-react/lib/esm/components/Button/Button';
import { format, parseISO } from 'date-fns';
import { nl } from 'date-fns/locale';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import {
  DataProductPlanSubscription, useCreateApiKeyMutation,
  useDeleteApiKeyMutation,
} from '../../generated/gql/types';
import { WeCityOrganizationIdHeader } from '../../Global';
import CopyToClipboardButton from './CopyToClipboardButton';

function SubscriptionTabGroup({ subscription }: ProductTabGroupProps) {
  const params = useParams();
  const [createApiSubscriptionKey] = useCreateApiKeyMutation();
  const [deleteApiSubscriptionKey] = useDeleteApiKeyMutation();

  function createSubscriptionKey() {
    createApiSubscriptionKey(
      {
        variables: {
          command: {
            subscriptionId: subscription.id,
          },
        },
        refetchQueries: [
          'GetSubscriptions',
        ],
        context: {
          headers: {
            [WeCityOrganizationIdHeader]: params.orgId,
          },
        },
      },
    );
  }

  function deleteSubscriptionKey(id: string) {
    // eslint-disable-next-line no-alert
    if (window.confirm('Are you sure you want to delete your API key?')) {
      deleteApiSubscriptionKey(
        {
          variables: {
            subscriptionId: subscription.id,
            keyId: id,
          },
          refetchQueries: [
            'GetSubscriptions',
          ],
          context: {
            headers: {
              [WeCityOrganizationIdHeader]: params.orgId,
            },
          },
        },
      );
    }
  }

  const customTabItem: DeepPartial<FlowbiteTabTheme> = {
    tabpanel: 'py-0',
    tablist: {
      styles: {
        fullWidth: 'divide-x divide-gray-400 shadow-none',
      },
      tabitem: {
        icon: 'mr-2 h-3 w-3',
        base: 'flex items-center justify-center p-4 rounded-t-lg text-sm font-medium first:ml-0 disabled:cursor-not-allowed disabled:text-gray-400 disabled:dark:text-gray-500 focus:ring-0',
        styles: {
          fullWidth: {
            active: {
              on: 'bg-white text-gray-900 text-sm',
              off: 'bg-gray-300 text-gray-600 text-sm',
            },
          },
        },
      },
    },
  };

  return (
    <div className="overflow-x-auto flex w-full bg-white rounded-lg">
      <Tabs.Group style="fullWidth" theme={customTabItem} className="w-full gap-0">
        <Tabs.Item
          title="API Keys"
          icon={FaUnlock}
        >
          <div className="flex justify-between items-center p-4">
            <div className="text-2xl font-bold py-4">Mijn API Keys</div>
            <FlowBiteButton size="sm" color="info" onClick={() => createSubscriptionKey()}>
              <div className="w-full justify-center items-center gap-2 inline-flex">
                <div className="text-sm font-medium w-full">
                  Genereer API Key
                </div>
              </div>
            </FlowBiteButton>
          </div>
          <div className="w-full overflow-x-auto">
            <Table className="w-full">
              <Table.Head>
                <Table.HeadCell className="!rounded-tl-none text-gray-500">API KEY</Table.HeadCell>
                <Table.HeadCell className=" text-gray-500">AANGEMAAKT OP</Table.HeadCell>
                <Table.HeadCell className="!rounded-tr-none">
                  <span className="sr-only">Edit</span>
                </Table.HeadCell>
              </Table.Head>
              <Table.Body className="divide-y">
                {subscription.keys.map((key) => (
                  <Table.Row className="" key={key.id}>
                    <Table.Cell className="whitespace-nowrap">
                      <div className="text-gray-900 font-medium">
                        <div className="flex shrink items-center rounded-lg p-3 border border-gray-300 gap-x-2">
                          {key.key}
                          <CopyToClipboardButton
                            content={<FontAwesomeIcon icon={icon({ name: 'clipboard', family: 'classic', style: 'regular' })} className="cursor-pointer" />}
                            clipboardData={key.key}
                            tooltipMessage="Copied API key"
                          />
                        </div>
                      </div>
                    </Table.Cell>
                    <Table.Cell className="whitespace-nowrap">
                      <div className="text-gray-400">
                        {format(parseISO(key.createdAt), 'dd MMM y', { locale: nl })}
                      </div>
                    </Table.Cell>
                    <Table.Cell className="whitespace-nowrap">
                      <button onClick={() => deleteSubscriptionKey(key.id)} type="button" className="text-red-700">Verwijder</button>
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </div>
        </Tabs.Item>
        <Tabs.Item title="Logboek" className="bg-gray-50" icon={FaArchive} />
        <Tabs.Item title="Serviceverzoeken" icon={FaBell} />
      </Tabs.Group>
    </div>
  );
}

type ProductTabGroupProps = {
  subscription: DataProductPlanSubscription
};

export default SubscriptionTabGroup;
