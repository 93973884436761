import React from 'react';
import { CustomFlowbiteTheme } from 'flowbite-react';
import { Card as FlowBiteCard } from 'flowbite-react/lib/esm/components/Card/Card';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Link } from 'react-router-dom';
import header from '../../images/library/usecase.png';
import InfoButton from './InfoButton';
import { UseCaseRoute } from '../Routes';

function UseCaseCard({ useCase, productId }: UseCaseCardProps) {
  const customTheme: CustomFlowbiteTheme['card'] = {
    root: {
      base: 'bg-gray-400/10 shadow-none rounded-t-lg',
      children: 'bg-gray-400/10 rounded-lg',
    },
  };

  return (
    <FlowBiteCard
      theme={customTheme}
      className="max-w-sm rounded-lg break-inside-avoid"
      renderImage={() => (
        <div />
      )}
    >
      <div className="flex-col justify-between items-center inline-flex rounded-lg">
        <div className="flex-col items-center bg-gray-400/10 p-5 rounded-t-lg">
          <div className="flex-col justify-start items-start inline-flex">
            <div className="self-stretch text-gray-500 text-lg font-bold">{useCase?.useCaseTitle}</div>
            <div
              className="self-stretch grow shrink basis-0 text-gray-500 text-base font-normal leading-normal"
            >
              <p className="line-clamp-3">
                {useCase?.useCaseDescription}
              </p>
            </div>

            <div className="flex justify-end w-full">
              <Link to={UseCaseRoute(productId)}>
                <InfoButton>
                  <div className="text-sm font-medium">Lees praktijkvoorbeeld</div>
                  <FontAwesomeIcon
                    icon={icon({ name: 'arrow-right', family: 'classic', style: 'solid' })}
                    size="lg"
                    className="text-white"
                  />
                </InfoButton>
              </Link>
            </div>
          </div>
        </div>
        <div className="w-full h-36 justify-center items-center gap-6 inline-flex bg-gray-400 rounded-b-lg">
          <img src={header} alt="Use case logo" className="h-full inline-flex rounded-b-lg" />
        </div>
      </div>
    </FlowBiteCard>
  );
}

type UseCaseCardProps = {
  useCase: any
  productId: string
};

export default UseCaseCard;
