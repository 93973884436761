import React, {
  useEffect, useState,
} from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import {
  Badge, Button as FlowBiteButton, Button, Textarea, TextInput,
  Dropdown,
} from 'flowbite-react';
import { useForm } from 'react-hook-form';
import { DropdownItem } from 'flowbite-react/lib/esm/components/Dropdown/DropdownItem';
import {
  DataProductDataType,
  Product_ProductAvailability_MutationInput,
  Product_ProductLanguage_MutationInput,
  Product_ProductType_MutationInput,
  Product_ProductVisibility_MutationInput,
  Product_ReviewState_MutationInput,
  ProductUpdate_ReviewState_MutationInput,
  useCreateGenericProductMutation,
  useGetGenericProductQuery,
  useUpdateGenericProductMutation,
} from '../../../generated/gql/types';
import { syncOfferWithLocalStorage } from '../../../Helpers';
import {
  GenericOfferWizardThemesRoute,
  MyOfferingsOverviewRoute,
} from '../../Routes';
import FormField from '../../library/form/FormField';
import WizardStep from '../../library/form/WizardStep';
import { GenericProductForm } from '../../../productForms';
import Loader from '../../Loader';
import { WeCityOrganizationIdHeader } from '../../../Global';
import useProductFilters, { ProductLocation } from '../../hooks/useProductFilters';

function GenericYourProduct() {
  const navigate = useNavigate();
  const params = useParams();

  const { data: cmsProduct, loading: cmsProductLoading } = useGetGenericProductQuery({
    context: {
      clientName: 'cms',
      headers: {
        [WeCityOrganizationIdHeader]: params.orgId,
      },
    },
    fetchPolicy: 'network-only',
    variables: {
      id: params.productId ?? '',
    },
  });

  const [createProduct, { loading: createLoading }] = useCreateGenericProductMutation({
    context: {
      clientName: 'cms',
      headers: {
        [WeCityOrganizationIdHeader]: params.orgId,
      },
    },
  });

  const productFilters = useProductFilters();
  const [updateProduct, { loading: updateLoading }] = useUpdateGenericProductMutation({
    context: {
      clientName: 'cms',
      headers: {
        [WeCityOrganizationIdHeader]: params.orgId,
      },
    },
  });

  const {
    register,
    handleSubmit,
  } = useForm<Fields>();

  const [selectedLocations, setSelectedLocations] = useState<ProductLocation[]>([]);
  const toggleLocation = (location: ProductLocation) => {
    if (selectedLocations.includes(location)) {
      setSelectedLocations(selectedLocations.filter((f) => f !== location));
    } else {
      setSelectedLocations([...selectedLocations, location]);
    }
  };

  useEffect(() => {
    if (cmsProduct?.Product?.id && params.productId) {
      syncOfferWithLocalStorage(params.productId, cmsProduct?.Product.id);
      const cmsLocations = cmsProduct?.Product?.locations?.map((loc) => ({ label: loc.label, type: loc.type } as ProductLocation)) ?? [];
      setSelectedLocations(cmsLocations);
    }
  }, [cmsProduct, cmsProductLoading]);

  const onSubmit = handleSubmit((fields) => {
    const data = {
      locations: selectedLocations.map((loc) => ({ label: loc.label, type: loc.type })),
      title: fields.name,
      tags: [],
      description: fields.description,
    };
    if (cmsProduct?.Product?.id) {
      updateProduct({
        variables: {
          id: cmsProduct?.Product?.id,
          data: {
            ...data,
            reviewState: ProductUpdate_ReviewState_MutationInput.Draft,
          },
        },
      }).then((_) => {
        navigate(GenericOfferWizardThemesRoute(params.orgId, params.productId, params.productType));
      });
    } else {
      createProduct({
        variables: {
          data: {
            ...data,
            id: cmsProduct?.Product?.id ?? params.productId,
            pricePlans: [],
            productAvailability: Product_ProductAvailability_MutationInput.Private,
            productVisibility: Product_ProductVisibility_MutationInput.Private,
            productType: params.productType as Product_ProductType_MutationInput.Consultancy,
            productLanguage: Product_ProductLanguage_MutationInput.English,
            productDataHolder: params.orgId || 'undefined',
            tags: [],
            themes: [],
            reviewState: Product_ReviewState_MutationInput.Accepted,
          },
        },
      }).then((_) => {
        navigate(GenericOfferWizardThemesRoute(params.orgId, params.productId, params.productType));
      });
    }
  });

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <WizardStep form={GenericProductForm} currentStep={0} onSubmit={onSubmit} key={cmsProductLoading ? 'loading' : 'loaded'}>
      {!cmsProductLoading ? (
        <>
          <FormField fieldName="name" label="Kies een naam/titel van je product" instruction="Max. 50 karakters" appendAsterix>
            <TextInput
              {...register('name', { value: cmsProduct?.Product?.title ?? '', required: true, maxLength: 50 })}
            />
          </FormField>

          <FormField fieldName="description" label="Beschrijf wat je met dit product kan doen" instruction="Max. 1000 karakters" appendAsterix>
            <Textarea
              rows={8}
              id="description"
              placeholder="bijv. Gemeentelijke bereikbaarheid van publieksvoorzieningen..."
              required
              maxLength={1000}
              {...register('description', { value: cmsProduct?.Product?.description ?? '' })}
              defaultValue={cmsProduct?.Product?.description ?? ''}
            />
          </FormField>

          <FormField fieldName="locations" label="Locaties">
            <div className="max-w-screen-md border border-1 rounded-xl p-2 flex gap-2">
              <Dropdown id="formats" placeholder="Selecteer" label="Selecteer" inline>
                {productFilters?.allLocations.map((location) => (
                  <DropdownItem key={`${location.label}-${location.type}`} value={location.label} onClick={() => toggleLocation(location)}>{location.label}</DropdownItem>
                ))}
              </Dropdown>
              {selectedLocations.map((location) => (
                <Badge key={`${location.label}-${location.type}`} className="px-2">
                  <button
                    type="button"
                    className="flex rounded-l-md text-sm font-medium text-gray-500 center-content"
                    onClick={() => {
                      toggleLocation(location);
                    }}
                  >
                    <span className="capitalize">{location.label}</span>
                    {selectedLocations.includes(location) && <FontAwesomeIcon className="my-auto ml-2" icon={icon({ name: 'xmark', family: 'classic', style: 'regular' })} color="gray" />}
                  </button>
                </Badge>
              ))}
            </div>
          </FormField>

          <div className="flex gap-x-4">
            <Link to={MyOfferingsOverviewRoute(params.orgId)}>
              <FlowBiteButton size="lg" color="light">
                <div className="w-full justify-center items-center gap-2 inline-flex">
                  <div className="text-xs lg:text-sm font-medium w-full">Terug</div>
                </div>
              </FlowBiteButton>
            </Link>

            {!createLoading && !updateLoading ? (
              <Button
                type="submit"
                className="flex items-center justify-self-center self-start rounded-lg text-center font-medium"
              >
                <div className="px-0.5 py-0.5">Volgende</div>
              </Button>
            ) : (
              <Button
                disabled
                type="button"
                className="inline-flex opacity:50 items-center justify-self-center self-start rounded-lg text-center font-medium"
              >
                <div className="px-0.5 py-0.5">Volgende</div>
              </Button>
            )}
          </div>
        </>
      ) : (<Loader />)}
    </WizardStep>
  );
}

type Fields = {
  name: string
  description: string,
  dmiCommon: DataProductDataType
  locations: string[]
};

export default GenericYourProduct;
