import { Card } from 'flowbite-react';
import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { PricePlanForProductWizardRoute } from '../../Routes';
import {
  PricePlan, PricePlan_PricePlanType, ProductUpdate_PricePlansRelationshipInput, useUpdateGenericProductMutation,
} from '../../../generated/gql/types';
import LinkPriceplan from '../LinkPriceplan';
import { UnifiedProduct } from '../../../Helpers';
import { WeCityOrganizationIdHeader } from '../../../Global';
// import { PricePlanOneTime, PricePlanRecurring } from '../../../generated/gql/types';

interface IPricePlanSectionProps {
  plans: PricePlan[]
  product: UnifiedProduct
  refetchProduct: () => void
}

function PricePlanSection({ plans, product, refetchProduct }: IPricePlanSectionProps) {
  const params = useParams();
  const [updateProduct] = useUpdateGenericProductMutation({
    context: {
      clientName: 'cms',
      fetchPolicy: 'network-only',
      headers: {
        [WeCityOrganizationIdHeader]: params.orgId,
      },
    },
  });

  function unlinkPricePlan(priceplanToUnlink: string) {
    const filteredPricePlans = product.pricePlanIds?.filter((pricePlanId) => pricePlanId !== priceplanToUnlink);
    updateProduct({
      variables: {
        id: product.id,
        data: {
          pricePlans: filteredPricePlans?.map((pricePlanId) => ({ value: pricePlanId, relationTo: 'price_plans' } as ProductUpdate_PricePlansRelationshipInput)),
        },
      },
    });
  }

  return (
    <>
      <div className="flex">
        <div className="grow h-[1px] bg-gray-300 my-auto mr-10" />
        <div className="mx-auto text-xl font-bold text-gray-500">{`${plans.length} Price Plans`}</div>
        <div className="grow h-[1px] bg-gray-300 my-auto ml-10" />
      </div>
      <div className="grid grid-cols-3 gap-5 ">

        <LinkPriceplan products={[product]} refetch={refetchProduct} />
        {
          plans.map((plan) => (
            <Card key={plan.id} className="h-[335px]">
              <div className="flex w-full">
                <div className="text-xl font-bold text-gray-500 my-auto">{plan.name}</div>
                <button type="button" className="ml-auto hover:bg-gray-100 p-2" onClick={() => unlinkPricePlan(plan.id)}>
                  <FontAwesomeIcon
                    icon={icon({ name: 'link-slash', family: 'classic', style: 'solid' })}
                    className="text-primary-700"
                  />
                </button>
              </div>
              <div className="flex flex-col gap-2">
                {
                  (plan.pricePlanType === PricePlan_PricePlanType.Subscription || plan.pricePlanType === PricePlan_PricePlanType.OneTimeSubscription) && (
                    <div>
                      {`
                      ${plan.priceRecurring} euro
                      per
                      ${plan.recurringPricePer?.toString()} `}
                    </div>
                  )
                }
                {
                  (plan.pricePlanType === PricePlan_PricePlanType.OneTime || plan.pricePlanType === PricePlan_PricePlanType.OneTimeSubscription) && (
                    <div>
                      {`${plan.priceOneTime}
                       euro, eenmalig`}
                    </div>
                  )
                }
              </div>
              <div className="flex mt-auto"><Link to={PricePlanForProductWizardRoute(params.orgId, plan.id, params.productId, params.productType)} className="ml-auto hover:underline text-blue">Edit</Link></div>
            </Card>
          ))
        }
        <Card className="flex border-blue-400 border-2">
          <div className="text-xl font-bold text-gray-500">Maak een nieuw prijsplan</div>
          <Link to={PricePlanForProductWizardRoute(params.orgId, crypto.randomUUID(), params.productId, params.productType)} className="ml-auto hover:underline text-blue">Maak een nieuw price plan</Link>
        </Card>
      </div>
    </>
  );
}

export default PricePlanSection;
