import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
  Badge, Button, Button as FlowBiteButton,
} from 'flowbite-react';
import { useForm } from 'react-hook-form';
import { WeCityOrganizationIdHeader } from '../../../Global';
import { syncOfferWithLocalStorage } from '../../../Helpers';
import {
  MutationProduct_TagsInput,
  useGetGenericProductQuery,
  useUpdateGenericProductMutation,
} from '../../../generated/gql/types';
import {
  GenericOfferWizardProductVisibilityRoute,
  GenericOfferWizardYourProductRoute,
} from '../../Routes';
import FormField from '../../library/form/FormField';
import WizardStep from '../../library/form/WizardStep';
import { GenericProductForm } from '../../../productForms';
import Loader from '../../Loader';
import useProductFilters from '../../hooks/useProductFilters';

function GenericProductThemes() {
  const navigate = useNavigate();
  const params = useParams();
  const productFilters = useProductFilters();
  const { data: cmsProduct, loading: cmsProductLoading } = useGetGenericProductQuery({
    context: {
      clientName: 'cms',
      headers: {
        [WeCityOrganizationIdHeader]: params.orgId,
      },
    },
    fetchPolicy: 'network-only',
    variables: {
      id: params.productId ?? '',
    },
  });

  const [selectedThemes, setSelectedThemes] = useState<string[]>([]);

  const [updateProduct, { loading: updateLoading }] = useUpdateGenericProductMutation({
    context: {
      clientName: 'cms',
      headers: {
        [WeCityOrganizationIdHeader]: params.orgId,
      },
    },
  });
  const {
    handleSubmit,
  } = useForm<Fields>();

  useEffect(() => {
    if (cmsProduct?.Product?.id && params.productId) {
      setSelectedThemes(cmsProduct.Product.themes?.map((t) => t.label?.toString() ?? '') ?? []);
      syncOfferWithLocalStorage(params.productId, cmsProduct.Product.id);
    }
  }, [cmsProduct]);

  const isThemeSelected = (theme: string) => selectedThemes.includes(theme);
  const toggleTheme = (theme: string) => {
    if (isThemeSelected(theme)) {
      setSelectedThemes(selectedThemes.filter((t) => t !== theme));
    } else if (selectedThemes.length < 4) {
      setSelectedThemes([...selectedThemes, theme]);
    }
  };

  const onSubmit = handleSubmit(() => {
    if (cmsProduct?.Product?.id) {
      updateProduct({
        variables: {
          id: cmsProduct?.Product?.id,
          data: {
            themes: selectedThemes.map((c) => ({ id: c, label: c } as MutationProduct_TagsInput)),
          },
        },
      }).then((_) => {
        navigate(GenericOfferWizardProductVisibilityRoute(params.orgId, params.productId, params.productType));
      });
    }
  });

  return (
    <WizardStep form={GenericProductForm} currentStep={1} onSubmit={onSubmit}>
      {!cmsProductLoading
        ? (
          <>
            <FormField fieldName="themes" label="Thema's" appendAsterix instruction="Selecteer maximaal 4 thema's">
              <div className="flex flex-wrap gap-2 p-2 border-1 border rounded-xl max-w-screen-md">
                {
                  productFilters?.themes.map((theme) => (
                    <Badge key={theme.label} className="px-2 inline" color={isThemeSelected(theme.label) ? 'success' : 'info'}>
                      <button
                        type="button"
                        className="flex rounded-l-md text-sm font-medium text-gray-500 center-content"
                        onClick={() => {
                          toggleTheme(theme.label);
                        }}
                      >
                        {theme.label}
                        {isThemeSelected(theme.label) && <FontAwesomeIcon className="my-auto ml-2" icon={icon({ name: 'xmark', family: 'classic', style: 'regular' })} color="gray" />}
                      </button>
                    </Badge>
                  ))
                }
              </div>
            </FormField>

            <div className="flex gap-x-4">
              <Link to={GenericOfferWizardYourProductRoute(params.orgId, params.productId, params.productType)}>
                <FlowBiteButton size="lg" color="light">
                  <div className="w-full justify-center items-center gap-2 inline-flex">
                    <div className="text-xs lg:text-sm font-medium w-full">Terug</div>
                  </div>
                </FlowBiteButton>
              </Link>

              {!updateLoading ? (
                <Button
                  type="submit"
                  className="flex items-center justify-self-center self-start rounded-lg text-center font-medium"
                >
                  <div className="px-0.5 py-0.5">Volgende</div>
                </Button>
              ) : (
                <Button
                  disabled
                  type="button"
                  className="inline-flex opacity:50 items-center justify-self-center self-start rounded-lg text-center font-medium"
                >
                  <div className="px-0.5 py-0.5">Volgende</div>
                </Button>
              )}
            </div>
          </>
        ) : <Loader />}
    </WizardStep>
  );
}

type Fields = {
  themes: string[]
};

export default GenericProductThemes;
