export const WeCityOrganizationIdHeader = 'wecity-organization-id';
export const OfferingsLocalStorageKey = 'wecity-offerings';
export const WecityUserIdHeader = 'wecity-user-id';

export type ProductVisibility = { label: string, value: string };
export const productVisibilities: ProductVisibility[] = [
  {
    label: 'Public',
    value: 'public',
  },
  {
    label: 'Organization',
    value: 'organization',
  },
  {
    label: 'Private',
    value: 'private',
  },
  {
    label: 'DMI only',
    value: 'dmiOnly',
  },
];

export type CatalogProductType = { label: string, value: string, icon?: string, imagePath?: string };
export type ProductType = 'dataproduct' | 'consultancy' | 'hardware' | 'application';
export const productTypes: CatalogProductType[] = [
  { label: 'Dataproduct', value: 'dataproduct' },
  { label: 'Consultancy', value: 'consultancy' },
  { label: 'Applicatie', value: 'application' },
  { label: 'Hardware', value: 'hardware' },
];
export const getCatalogProductTypeLabelFromValue = (value: string): string => productTypes.find((t) => t.value === value)?.label ?? '';

const Global = {
  productVisibilities,
};
export default Global;
