import { UUID } from 'node:crypto';
import Ajv from 'ajv';
import { $Validator, FromSchema, wrapValidatorAsTypeGuard } from 'json-schema-to-ts';
import MersenneTwister from 'mersenne-twister';
import odpsSchema from './odps.json';
import { OfferingsLocalStorageKey, CatalogProductType, productTypes } from './Global';
import {
  PricePlan_PricePlanType, Product, DataProduct,
} from './generated/gql/types';

// Setup ajv json schema validator
const ajv = new Ajv({ allErrors: true });
const $validate: $Validator = (schema, unknownData) => ajv.validate(schema, unknownData);
const validateAsTypeGuard = wrapValidatorAsTypeGuard($validate);

export type ODPS = FromSchema<typeof odpsSchema>;

const cardColors = ['#ACC7D8', '#A2BFD0', '#90ADC1', '#708DA3', '#607F94', '#95BBD3', '#8BB3CB', '#79A1BB', '#59819D', '#49738E', '#90B0C5', '#86A7BD', '#7496AD', '#54768F', '#446880', '#8BA6B7', '#819DAF', '#6F8C9F', '#4E6C81', '#3E5D73'];
const mt = new MersenneTwister();

export function randomColorWithSeed(seed: number) {
  mt.init_seed(seed);
  return cardColors[Math.floor(mt.random() * (cardColors.length - 1))];
}

export function syncOfferWithLocalStorage(localProductId: string, backendProductId: string) {
  let offers: Offer[] = [];
  if (localStorage.getItem(OfferingsLocalStorageKey)) {
    offers = (JSON.parse(localStorage.getItem(OfferingsLocalStorageKey) ?? '[]') ?? []) as Offer[];
  }

  if (!offers.find((o) => o.id === localProductId)) {
    offers = [...offers, { id: localProductId, savedToBackend: false } as Offer];
    localStorage.setItem(OfferingsLocalStorageKey, JSON.stringify(offers));
  }

  if (backendProductId === localProductId) {
    localStorage.setItem(OfferingsLocalStorageKey, JSON.stringify(offers.map((o: Offer) => ((o.id === localProductId) ? ({ ...o, savedToBackend: true } as Offer) : o))));
  }
}

export type Offer = {
  id: UUID
  isWebservice?: boolean,
  dataProductType?: String,
  hasConfirmedOrganization?: boolean,
  language?: String,
  savedToBackend?: boolean
};

/**
 * @throws {Error | TypeError}
 */
export function validateOdpsWithExceptions(rawOdps: any): ODPS | undefined {
  const parsedOdps = JSON.parse(rawOdps);

  if (parsedOdps) {
    if (validateAsTypeGuard(odpsSchema, parsedOdps)) {
      return parsedOdps;
    }
    if (ajv.errors) {
      throw new Error(ajv.errors.map((e) => `${e.message} at ${e.schemaPath}`).join(', '));
    }
  }

  return undefined;
}

export default function validateOdps(rawOdps: any): ODPS | undefined {
  try {
    const parsedOdps = JSON.parse(rawOdps);

    if (parsedOdps) {
      if (validateAsTypeGuard(odpsSchema, parsedOdps)) {
        return parsedOdps;
      }
    }
  } catch (error) { /* empty */ }

  return undefined;
}

export function PricePlanTypeToString(plan: PricePlan_PricePlanType) {
  switch (plan) {
    case PricePlan_PricePlanType.OneTime:
      return 'Eenmalig';
    case PricePlan_PricePlanType.OneTimeSubscription:
      return 'Abonnement + eenmalige kosten';
    case PricePlan_PricePlanType.Subscription:
      return 'Abonnement';
    default:
      return '';
  }
}

export function timeFrameToString(period: string) {
  switch (period) {
    case 'half_year':
      return 'Half jaar';
    case 'month':
      return 'Maand';
    case 'quarter':
      return 'Kwartaal';
    case 'year':
      return 'Jaar';
    default:
      return '';
  }
}

export function getIconForTheme(theme: string): string | undefined {
  switch (theme?.toLowerCase()) {
    case 'fiets': return 'bicycle';
    case 'auto': return 'car';
    case 'toegankelijkheid': return 'accessibility';
    case 'bereikbaarheid': return 'road';
    case 'water': return 'water';
    case 'bodem': return 'soil';
    case 'parkeren': return 'parking';
    case 'natuur': return 'nature';
    case 'milieu': return 'environment';
    case 'bouw': return 'construction';
    case 'infrastructuur': return 'road';
    case 'weer': return 'weather';
    default: return undefined;
  }
}

export const cardHeaderColors = (productTypeValue: string) => {
  switch (productTypeValue) {
    case 'dataproduct':
      return 'bg-blue-400';
    case 'consultancy':
      return 'bg-cyan-300';
    case 'application':
      return 'bg-purple-300';
    case 'hardware':
      return 'bg-indigo-300';
    default: return 'bg-indigo-400';
  }
};

export type UnifiedProduct = {
  id: string,
  productType: CatalogProductType,
  name: string,
  themes: string[],
  description: string,
  originalProduct: Product | DataProduct,
  owner: string,
  organization: string,
  tags: string[],
  createdAt?: Date,
  reviewState: 'DRAFT' | 'PENDING' | 'AMEND' | 'ACCEPTED'
  pricePlanIds: string[];
  hasDmiCommonPricePlan?: boolean;
};

export function unifyDataProduct(dataProduct: DataProduct): UnifiedProduct {
  const odps = JSON.parse(dataProduct.openDataProductSpecification ?? '{}');
  return {
    id: dataProduct.id,
    productType: productTypes.find((t) => t.value === 'dataproduct')!,
    name: dataProduct.name,
    themes: [], // todo
    description: dataProduct.description,
    originalProduct: dataProduct,
    owner: odps.product?.en?.legalname,
    organization: odps.product?.en?.legalname,
    tags: odps?.en?.tags?.map((t: { name: string }) => t.name!) ?? [],
    reviewState: odps.product?.en?.status,
    pricePlanIds: [],
  };
}

export function unifyGenericProduct(product: Product): UnifiedProduct {
  return {
    id: product.id!,
    productType: productTypes.find((t) => t.value === product.productType)!,
    name: product.title,
    themes: product.themes?.map((t) => t.label!) || [],
    description: product.description,
    originalProduct: product,
    owner: product.productDataHolder,
    organization: product.productDataHolder,
    createdAt: new Date(product.createdAt),
    tags: product.tags?.map((t) => t.label!) || [],
    reviewState: product.reviewState,
    pricePlanIds: product.pricePlans?.map((p) => p.value?.id!) || [],
    hasDmiCommonPricePlan: product.pricePlans?.some((p) => p.value?.dmiCommon),
  };
}

export function unifyProductQuery(product: Product | DataProduct | undefined): UnifiedProduct | undefined {
  if (!product) return undefined;
  if ('openDataProductSpecification' in product) {
    return unifyDataProduct(product);
  }
  return unifyGenericProduct(product);
}

export function capitalize(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export type OdpsValidationResult = {
  success: boolean
  errorMessage?: string
  validOdps?: ODPS
};
