import React from 'react';
import { Footer as FlowBiteFooter } from 'flowbite-react';
import { getYear } from 'date-fns';
import { brands } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import logo from '../images/wecity_logo_main.png';
import { CatalogRoute, MySubscriptionsOverviewRoute } from './Routes';

function Footer() {
  return (
    <FlowBiteFooter container className="bg-white">
      <div className="relative flex-col items-center justify-center mx-auto w-full max-w-screen-xl">
        <div className="grid grid-cols-2 gap-8 sm:mt-4 sm:grid-cols-3 sm:gap-6">
          <div>
            <FlowBiteFooter.Title title="WeCity" className="text-gray-900" />
            <FlowBiteFooter.LinkGroup col>
              <FlowBiteFooter.Link href="https://wecity.nl/nl/over-ons">Over ons</FlowBiteFooter.Link>
              <FlowBiteFooter.Link href="https://wecity.nl/nl/blog">Blog</FlowBiteFooter.Link>
            </FlowBiteFooter.LinkGroup>
          </div>
          <div>
            <FlowBiteFooter.Title title="Links" className="text-gray-900" />
            <FlowBiteFooter.LinkGroup col>
              <FlowBiteFooter.Link href={CatalogRoute()}>Data Catalogus</FlowBiteFooter.Link>
              <FlowBiteFooter.Link href={MySubscriptionsOverviewRoute()}>Mijn Producten</FlowBiteFooter.Link>
            </FlowBiteFooter.LinkGroup>
          </div>
          <div>
            <FlowBiteFooter.Title title="Legal" className="text-gray-900" />
            <FlowBiteFooter.LinkGroup col>
              <FlowBiteFooter.Link href="https://wecity.nl/nl/privacybeleid">Privacy Policy</FlowBiteFooter.Link>
              <FlowBiteFooter.Link href="https://wecity.nl/nl/algemene-voorwaarden">Voorwaarden</FlowBiteFooter.Link>
            </FlowBiteFooter.LinkGroup>
          </div>
        </div>
        <FlowBiteFooter.Divider />
        <div className="w-full sm:flex sm:items-center sm:justify-between">
          <Link to="https://wecity.nl"><img src={logo} alt="Logo" className="h-6 sm:h-9" /></Link>
          <FlowBiteFooter.Copyright href="https://wecity.nl" by="WeCity" year={getYear(new Date())} />
          <div className="mt-4 flex space-x-6 sm:mt-0 sm:justify-center">
            <Link to="https://www.linkedin.com/company/we-city/">
              <FontAwesomeIcon
                icon={brands('linkedin')}
                size="lg"
              />
            </Link>
            <Link to="https://twitter.com/wecity1">
              <FontAwesomeIcon
                icon={brands('twitter')}
                size="lg"
              />
            </Link>
          </div>
        </div>
      </div>
    </FlowBiteFooter>
  );
}

export default Footer;
