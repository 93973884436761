import React, { useEffect, useState } from 'react';
import { Button as FlowBiteButton } from 'flowbite-react/lib/esm/components/Button/Button';
import {
  Label, Modal, TextInput,
} from 'flowbite-react';
import { ModalHeader } from 'flowbite-react/lib/esm/components/Modal/ModalHeader';
import { ModalBody } from 'flowbite-react/lib/esm/components/Modal/ModalBody';
import { ModalFooter } from 'flowbite-react/lib/esm/components/Modal/ModalFooter';
import { useForm } from 'react-hook-form';
import * as Sentry from '@sentry/react';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CreateUserCommand, useCreateUserMutation } from '../../generated/gql/types';

function Registration() {
  const [modalOpen, setModalOpen] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
  } = useForm<CreateUserCommand>();

  const [success, setSuccess] = useState<boolean>(false);

  useEffect(() => {
    setSuccess(false);
  }, [modalOpen]);

  const [createUser, {
    loading: registerUserLoading,
    error: registerUserError,
    reset: resetCreateUser,
  }] = useCreateUserMutation({});

  const onSubmit = handleSubmit((createUserCommand) => {
    createUser({
      variables: {
        command: createUserCommand,
      },
    }).then(() => {
      setSuccess(true);
    }).catch((e) => {
      const scope = new Sentry.Scope();
      scope.setLevel('error');
      Sentry.captureMessage(e.toString(), scope);
    });
  });

  const onClose = () => {
    resetCreateUser();
    setModalOpen(false);
    reset();
  };

  return (
    <>
      <FlowBiteButton size="sm" color="gray" onClick={() => { setModalOpen(true); }}>
        <div className="w-full justify-center items-center gap-2 inline-flex">
          Registreer als DMI deelnemer
        </div>
      </FlowBiteButton>

      <Modal show={modalOpen} popup onClose={onClose} dismissible>
        <ModalHeader>
          <div className="p-2">Registreer als DMI deelnemer</div>
        </ModalHeader>
        <ModalBody className="flex w-full flex-col justify-center mx-auto px-14 pb-0">
          <form id="registrationForm" className="gap-2 flex flex-col" onSubmit={onSubmit}>
            <div className="flex justify-between mb-3">
              <div>
                <div className="mb-2">
                  <Label htmlFor="firstname" value="Voornaam:" />
                </div>
                <TextInput autoFocus id="firstname" placeholder="Uw voornaam" {...register('firstname', { required: true })} disabled={success} />
              </div>
              <div>
                <div className="mb-2">
                  <Label htmlFor="lastname" value="Achternaam:" />
                </div>
                <TextInput id="lastname" required placeholder="Uw achternaam" {...register('lastname', { required: true })} disabled={success} />
              </div>
            </div>
            <div className="block">
              <Label htmlFor="kvk" value="KVK-nummer:" />
            </div>
            <TextInput id="coc" required placeholder="KVK-nummer bestaande uit 8 getallen, bijv. 01234567" {...register('coc', { required: true, pattern: /\d{8}/ })} disabled={success} />
            <div className="block">
              <Label htmlFor="email" value="E-mailadres:" />
            </div>
            <TextInput id="email" type="email" required placeholder="Uw e-mailadres" {...register('email', { required: true })} disabled={success} />
          </form>
          {(success) && (
            <div className="mt-4 flex bg-green-400 p-2 rounded-lg text-white border-2">
              <div className="my-auto px-2 text-3xl">
                <FontAwesomeIcon
                  icon={icon({ name: 'check-circle', family: 'classic', style: 'regular' })}
                />
              </div>
              <div className="italic px-2">
                Bedankt voor uw registratie! U ontvangt van ons een e-mail ter bevestiging.
              </div>
            </div>
          )}
        </ModalBody>
        <ModalFooter className="flex-col gap-5 px-14">
          <div className="flex w-full">
            <FlowBiteButton size="sm" color="info" className="mr-auto" disabled={registerUserLoading || success} form="registrationForm" type="submit">
              <div className="w-full justify-center items-center gap-2 inline-flex">
                Registratie versturen
              </div>
            </FlowBiteButton>
            {(success) && (
              <FlowBiteButton size="sm" color="success" className="ml-auto" type="button" onClick={() => onClose()}>
                <div className="w-full justify-center items-center gap-2 inline-flex font-bold">
                  Venster sluiten
                </div>
              </FlowBiteButton>
            )}
          </div>
          {registerUserError && (
            <div className="block text-sm">
              Error: Er is een fout opgetreden bij het aanmaken van een account. Neem contact op met onze support
            </div>
          )}
        </ModalFooter>
      </Modal>
    </>
  );
}

export default Registration;
