export const LandingRoute = () => '/';

export const CatalogRoute = () => '/';
export const CatalogProductRoute = (productType?: string, productId?: string) => (productId ? `/catalog/${productType}/${productId}` : '/catalog/:productType/:productId');
export const UseCaseRoute = (productId?: string) => (productId ? `/use-case/${productId}` : '/use-case/:productId');

export const MySubscriptionsOverviewRoute = (orgId?: string) => (orgId ? `/my-subscriptions/${orgId}` : '/my-subscriptions/:orgId');
export const MyProductSubscriptionRoute = (orgId?: string, subscriptionId?: string) => ((orgId && subscriptionId) ? `/my-subscriptions/${orgId}/${subscriptionId}` : '/my-subscriptions/:orgId/:subscriptionId');

export const MyOfferingsOverviewRoute = (orgId?: string) => (orgId ? `/my-offerings/${orgId}` : '/my-offerings/:orgId');
export const MyProductOfferingRoute = (orgId?: string, productId?: string) => ((orgId && productId) ? `/my-offerings/${orgId}/${productId}` : '/my-offerings/:orgId/:productId');

export const SelectOfferWizardRoute = (orgId?: string) => (orgId ? `/my-offerings/${orgId}/select-offering` : '/my-offerings/:orgId/select-offering');

export const PricePlansOverviewRoute = (orgId?: string) => (orgId ? `/my-price-plans/${orgId}` : '/my-price-plans/:orgId');
export const PricePlanWizardRoute = (orgId?: string, pricePlanId?: string) => (orgId ? `/my-price-plans/${orgId}/new/${pricePlanId}` : '/my-price-plans/:orgId/new/:pricePlanId');
export const PricePlanForProductWizardRoute = (orgId?: string, pricePlanId?: string, productId?: string, productType?: string) => (orgId && productId ? `/product/${productType}/${productId}/price-plan/${pricePlanId}/${orgId}` : '/product/:productType/:productId/price-plan/:pricePlanId/:orgId');

export const OfferWizardIsWebserviceRoute = (orgId?: string, productId?: string) => (orgId && productId ? `/my-offerings/${orgId}/new/dataproduct/${productId}/is-webservice` : '/my-offerings/:orgId/new/:productType/:productId/is-webservice');
export const OfferWizardTellUsRoute = (orgId?: string, productId?: string) => (orgId ? `/my-offerings/${orgId}/new/dataproduct/${productId}/tell-us` : '/my-offerings/:orgId/new/:productType/:productId/tell-us');
export const OfferWizardGetStartedRoute = (orgId?: string, productId?: string) => (orgId ? `/my-offerings/${orgId}/new/dataproduct/${productId}/get-started` : '/my-offerings/:orgId/new/:productType/:productId/get-started');
export const OfferWizardLanguageRoute = (orgId?: string, productId?: string) => (orgId ? `/my-offerings/${orgId}/new/dataproduct/${productId}/language` : '/my-offerings/:orgId/new/:productType/:productId/language');
export const OfferWizardAutoFillOdpsRoute = (orgId?: string, productId?: string) => (orgId ? `/my-offerings/${orgId}/new/dataproduct/${productId}/autofill-odps` : '/my-offerings/:orgId/new/:productType/:productId/autofill-odps');
export const OfferWizardYourProductRoute = (orgId?: string, productId?: string) => (orgId ? `/my-offerings/${orgId}/new/dataproduct/${productId}/your-product` : '/my-offerings/:orgId/new/:productType/:productId/your-product');
export const OfferWizardProductVisibilityRoute = (orgId?: string, productId?: string) => (orgId ? `/my-offerings/${orgId}/new/dataproduct/${productId}/product-visibility` : '/my-offerings/:orgId/new/:productType/:productId/product-visibility');
export const OfferWizardYourDatasetRoute = (orgId?: string, productId?: string) => (orgId ? `/my-offerings/${orgId}/new/dataproduct/${productId}/your-dataset` : '/my-offerings/:orgId/new/:productType/:productId/your-dataset');
export const OfferWizardPricingPlansRoute = (orgId?: string, productId?: string) => (orgId ? `/my-offerings/${orgId}/new/dataproduct/${productId}/pricing-plans` : '/my-offerings/:orgId/new/:productType/:productId/pricing-plans');
export const OfferWizardLicenseAndTermsRoute = (orgId?: string, productId?: string) => (orgId ? `/my-offerings/${orgId}/new/dataproduct/${productId}/license-and-terms` : '/my-offerings/:orgId/new/:productType/:productId/license-and-terms');
export const OfferWizardDataHolderRoute = (orgId?: string, productId?: string) => (orgId ? `/my-offerings/${orgId}/new/dataproduct/${productId}/data-holder` : '/my-offerings/:orgId/new/:productType/:productId/data-holder');
export const OfferWizardImagesRoute = (orgId?: string, productId?: string) => (orgId ? `/my-offerings/${orgId}/new/dataproduct/${productId}/images` : '/my-offerings/:orgId/new/:productType/:productId/images');
export const OfferWizardReviewRoute = (orgId?: string, productId?: string) => (orgId ? `/my-offerings/${orgId}/new/dataproduct/${productId}/review` : '/my-offerings/:orgId/new/:productType/:productId/review');
export const OfferWizardCongratulationsRoute = (orgId?: string, productId?: string) => (orgId ? `/my-offerings/${orgId}/new/dataproduct/${productId}/congratulations` : '/my-offerings/:orgId/new/:productType/:productId/congratulations');

export const GenericOfferWizardYourProductRoute = (orgId?: string, productId?: string, productType?: string) => (orgId ? `/my-generic-offerings/${orgId}/new/${productId}/your-product/${productType}` : '/my-generic-offerings/:orgId/new/:productId/your-product/:productType');
export const GenericOfferWizardThemesRoute = (orgId?: string, productId?: string, productType?: string) => (orgId ? `/my-generic-offerings/${orgId}/new/${productId}/themes/${productType}` : '/my-generic-offerings/:orgId/new/:productId/themes/:productType');
export const GenericOfferWizardProductVisibilityRoute = (orgId?: string, productId?: string, productType?: string) => (orgId ? `/my-generic-offerings/${orgId}/new/${productId}/product-visibility/${productType}` : '/my-generic-offerings/:orgId/new/:productId/product-visibility/:productType');
export const GenericOfferWizardPricePlansroute = (orgId?: string, productId?: string, productType?: string) => (orgId ? `/my-generic-offerings/${orgId}/new/${productId}/pricing/${productType}` : '/my-generic-offerings/:orgId/new/:productId/pricing/:productType');
export const GenericOfferWizardReviewRoute = (orgId?: string, productId?: string, productType?: string) => (orgId ? `/my-generic-offerings/${orgId}/new/${productId}/review/${productType}` : '/my-generic-offerings/:orgId/new/:productId/review/:productType');

export const AdminCreateDataProduct = () => '/admin/products/create';
export const AdminDataProductsOverview = (orgId?: string) => (orgId ? `/admin/products/${orgId}` : '/admin/products/:orgId');
export const AdminUpdateDataProduct = (orgId?: string, productId?: string) => ((orgId && productId) ? `/admin/products/${orgId}/${productId}` : '/admin/products/:orgId/:productId');
