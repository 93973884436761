import React from 'react';
import { Navbar } from 'flowbite-react';
import { LandingRoute } from './Routes';
import logo from '../images/wecity_logo_main.png';

function SessionTimeoutPage() {
  return (
    <div className="container mx-auto">
      <Navbar
        fluid
        rounded
        className="mt-5 pb-5 mb-5 border-b"
      >
        <Navbar.Brand>
          <img src={logo} alt="Logo" className="mr-3 h-6 sm:h-9" />
        </Navbar.Brand>

      </Navbar>
      <div role="status">
        <p>Uw sessie is verlopen</p>
        <p>
          Log aub opnieuw in
          <a
            href={LandingRoute()}
            className="btn-card bg-wc-lightgray group flex h-min items-center justify-center p-0.5 px-2 m-5 text-center font-bold focus:z-10 rounded-lg inline-grid"
          >
            <span className="text-center rounded-md text-sm px-4 py-2">Inloggen</span>
          </a>
        </p>
      </div>
    </div>
  );
}

export default SessionTimeoutPage;
