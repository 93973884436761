import { Button, Table } from 'flowbite-react';
import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import {
  DataProduct,
  DataProductState,
  RoleUser,
  useAdminAcceptDataProductMutation,
  useAdminDeleteDataProductMutation,
  useAdminRequestAmendmentDataProductMutation,
  useGetAdminDataProductsQuery,
  useGetMyUserRolesQuery,
} from '../../generated/gql/types';
import Loader from '../Loader';
import validateOdps from '../../Helpers';
import { WeCityOrganizationIdHeader } from '../../Global';
import { AdminCreateDataProduct, AdminUpdateDataProduct } from '../Routes';

function DataProductsOverview() {
  const params = useParams();
  const { data: myRoles, loading: userRolesLoading } = useGetMyUserRolesQuery();
  const { data: dataProducts, loading: dataProductsLoading } = useGetAdminDataProductsQuery({
    context: {
      headers: {
        [WeCityOrganizationIdHeader]: params.orgId,
      },
    },
  });

  const [acceptDataProduct] = useAdminAcceptDataProductMutation({
    refetchQueries: [
      'GetAdminDataProducts',
    ],
    context: {
      headers: {
        [WeCityOrganizationIdHeader]: params.orgId,
      },
    },
  });

  const [requestAmendment] = useAdminRequestAmendmentDataProductMutation({
    refetchQueries: [
      'GetAdminDataProducts',
    ],
    context: {
      headers: {
        [WeCityOrganizationIdHeader]: params.orgId,
      },
    },
  });

  const [deleteProduct] = useAdminDeleteDataProductMutation({
    refetchQueries: [
      'GetAdminDataProducts',
    ],
    context: {
      headers: {
        [WeCityOrganizationIdHeader]: params.orgId,
      },
    },
  });

  function handleDelete(id: string) {
    // eslint-disable-next-line no-alert
    if (window.confirm('Are you sure you want to delete this product?')) {
      deleteProduct({ variables: { id } });
    }
  }

  if (userRolesLoading || dataProductsLoading) {
    return <Loader />;
  }

  function renderProductRow(product: Partial<DataProduct>): React.JSX.Element {
    const odps = validateOdps(product.openDataProductSpecification);
    if (product.id === undefined) {
      return <div>unknown</div>;
    }

    return (
      <Table.Row key={product.id} className="bg-white dark:border-gray-700 dark:bg-gray-800 cursor-pointer">
        <Table.Cell>
          {odps?.product.en.name ?? 'unknown'}
        </Table.Cell>
        <Table.Cell>
          {odps ? 'Yes' : 'No'}
        </Table.Cell>
        <Table.Cell>
          {product.active ? 'Yes' : 'No'}
        </Table.Cell>
        <Table.Cell>
          {product.dataType}
        </Table.Cell>
        <Table.Cell>
          <div className="flex gap-x-3 items-center">
            <div className="lowercase capitalize-first">
              {product.state ?? 'unknown'}
            </div>
            <div className="text-xl flex gap-x-2">
              {product.state === DataProductState.Pending && (
                <>
                  {odps && (
                    <button
                      type="button"
                      onClick={() => acceptDataProduct({
                        variables: {
                          id: product.id!,
                        },
                      })}
                    >
                      <FontAwesomeIcon
                        icon={icon({ name: 'check-square', family: 'classic', style: 'solid' })}
                        className="text-green-600"
                        title="Accepteer en publiceer dataproduct"
                      />
                    </button>
                  )}

                  <button
                    type="button"
                    title="Dataproduct wijzigen"
                    onClick={() => requestAmendment({
                      variables: {
                        id: product.id!,
                      },
                    })}
                  >
                    <FontAwesomeIcon
                      icon={icon({ name: 'comment-times', family: 'classic', style: 'solid' })}
                      className="text-orange-600"
                    />
                  </button>
                </>
              )}
            </div>
          </div>
        </Table.Cell>
        <Table.Cell>
          <div className="flex gap-x-3 text-xl">
            <Link title="Edit product properties" to={AdminUpdateDataProduct(params.orgId, product.id)}>
              <FontAwesomeIcon
                icon={icon({ name: 'pencil', family: 'classic', style: 'solid' })}
                className="text-primary-700"
              />
            </Link>

            {(product.state === DataProductState.Draft || product.state === DataProductState.Amend) && (
              <button
                type="button"
                onClick={() => handleDelete(product.id!)}
                title="Permanent delete product"
              >
                <FontAwesomeIcon
                  icon={icon({ name: 'times', family: 'classic', style: 'solid' })}
                  className="text-red-700"
                />
              </button>
            )}
          </div>
        </Table.Cell>
      </Table.Row>
    );
  }

  if (myRoles?.myUserRoles.find((r) => r === RoleUser.UserGlobalAdmin)) {
    return (
      <div className="mx-auto relative max-w-screen-xl flex items-center justify-center w-full bg-gray-100 py-6">
        <div className="flex-col w-full space-y-2">
          <Link to={AdminCreateDataProduct()}>
            <Button
              type="button"
              className="inline-flex items-center justify-self-center self-start rounded-lg p-1 text-center font-medium"
            >
              Create new product
            </Button>
          </Link>
          <Table hoverable>
            <Table.Head>
              <Table.HeadCell>
                Name
              </Table.HeadCell>
              <Table.HeadCell>
                Valid odps
              </Table.HeadCell>
              <Table.HeadCell>
                Active
              </Table.HeadCell>
              <Table.HeadCell>
                Type
              </Table.HeadCell>
              <Table.HeadCell>
                State
              </Table.HeadCell>
              <Table.HeadCell>
                <FontAwesomeIcon
                  icon={icon({ name: 'cogs', family: 'classic', style: 'solid' })}
                  className="text-primary-700 text-lg"
                />
              </Table.HeadCell>
            </Table.Head>
            <Table.Body className="divide-y">
              {dataProducts?.dataMarket.adminProducts.map((product: Partial<DataProduct>) => (
                renderProductRow(product)
              ))}
            </Table.Body>
          </Table>
        </div>
      </div>
    );
  }

  return <div>No access</div>;
}

export default DataProductsOverview;
