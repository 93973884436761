import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button } from 'flowbite-react';
import WizardStep from '../../library/form/WizardStep';
import { GenericProductForm } from '../../../productForms';
import { PricePlan, Product, useGetGenericProductQuery } from '../../../generated/gql/types';
import PricePlanSection from '../../library/form/PricePlanSection';
import { GenericOfferWizardProductVisibilityRoute, GenericOfferWizardReviewRoute } from '../../Routes';
import { UnifiedProduct, unifyGenericProduct } from '../../../Helpers';
import { WeCityOrganizationIdHeader } from '../../../Global';

function GenericYourProductPricePlans() {
  const params = useParams();
  const navigate = useNavigate();

  const { data: cmsProduct, refetch: refetchProduct, loading } = useGetGenericProductQuery({
    context: {
      clientName: 'cms',
      headers: {
        [WeCityOrganizationIdHeader]: params.orgId,
      },
    },
    fetchPolicy: 'network-only',
    variables: {
      id: params.productId ?? '',
    },
  });

  const pricePlans = cmsProduct?.Product?.pricePlans?.map((plan) => plan.value as PricePlan) ?? [];
  const [product, setProduct] = useState<UnifiedProduct>();

  useEffect(() => {
    if (!loading && cmsProduct) {
      const unifiedProduct = unifyGenericProduct(cmsProduct?.Product as Product);
      setProduct(unifiedProduct);
    }
  }, [loading, cmsProduct]);

  return (
    <WizardStep form={GenericProductForm} currentStep={3}>
      {product !== undefined && <PricePlanSection plans={pricePlans} product={product} refetchProduct={refetchProduct} />}
      <div className="flex gap-x-4">
        <Link to={GenericOfferWizardProductVisibilityRoute(params.orgId, params.productId, params.productType)}>
          <Button size="lg" color="light">
            <div className="w-full justify-center items-center gap-2 inline-flex">
              <div className="text-xs lg:text-sm font-medium w-full">Terug</div>
            </div>
          </Button>
        </Link>

        {loading ? (
          <Button
            type="button"
            disabled
            className="opacity:50 items-center justify-self-center self-start rounded-lg text-center font-medium"
          >
            <div className="px-0.5 py-0.5">Volgende</div>
          </Button>
        ) : (
          <Button
            type="button"
            onClick={() => navigate(GenericOfferWizardReviewRoute(params.orgId, params.productId, params.productType))}
            className="items-center justify-self-center self-start rounded-lg text-center font-medium"
          >
            <div className="px-0.5 py-0.5">Volgende</div>
          </Button>
        )}
      </div>
    </WizardStep>
  );
}

export default GenericYourProductPricePlans;
