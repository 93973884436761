import React, { Fragment } from 'react';
import { useParams } from 'react-router-dom';
import { Card } from 'flowbite-react';
import DataProductCard from '../library/DataProductCard';
import {
  DataProductPlanSubscription,
  PricePlan_PricePlanType,
  ProductOrder,
  useGetProductOrdersQuery,
  useGetSubscriptionsQuery,
} from '../../generated/gql/types';
import MySubscriptionsHero from './MySubscriptionsHero';
import { CatalogProductRoute, MyProductSubscriptionRoute } from '../Routes';
import { WeCityOrganizationIdHeader } from '../../Global';
import validateOdps from '../../Helpers';
import ProductCard from '../library/ProductCard';

function MySubscriptionsOverview() {
  const params = useParams();
  const { data, loading } = useGetSubscriptionsQuery({
    fetchPolicy: 'network-only',
    context: {
      headers: {
        [WeCityOrganizationIdHeader]: params.orgId,
      },
    },
  });
  const { data: productOrders, loading: productOrdersLoading } = useGetProductOrdersQuery({
    fetchPolicy: 'network-only',
    context: {
      clientName: 'cms',
      headers: {
        [WeCityOrganizationIdHeader]: params.orgId,
      },
    },
  });

  function renderSubscription(subscription: Partial<DataProductPlanSubscription>) {
    if (subscription.product) {
      const odps = validateOdps(subscription.product.openDataProductSpecification);

      if (odps) {
        return (
          <Fragment key={`mysubscription-${subscription.id}`}>
            <DataProductCard
              odps={odps}
              productRoute={MyProductSubscriptionRoute(params.orgId, subscription.id)}
              subscription={subscription}
            />
          </Fragment>
        );
      }
    }

    return '';
  }

  function renderProductOrders(order: Partial<ProductOrder>) {
    const product = order.product?.value;
    const plan = order.pricePlan?.value;

    if (product && plan) {
      return (
        <div key={`catalogproduct-${product.id}`}>
          <ProductCard
            title={product.title}
            description={product.description}
            themes={product.themes?.map((t) => t.label!) || []}
            tags={product.tags?.map((t) => t.label!)}
            providerName={product.productDataHolder}
            productType={product.productType}
            productRoute={CatalogProductRoute(product.productType, product.id || '')}
          />
          <Card key={plan.id} className="max-w-sm">
            <div className="flex">
              <div className="text-lg font-bold">
                Prijsplan:
              </div>
              <div className="flex flex-col ml-auto">
                <div className="text-xl font-bold text-gray-500 my-auto text-right">{plan.name}</div>
                <ul className="flex flex-col gap-2 mt-2">
                  {
                    (plan.pricePlanType === PricePlan_PricePlanType.Subscription || plan.pricePlanType === PricePlan_PricePlanType.OneTimeSubscription) && (
                      <li className="p-1 px-3 text-sm text-center rounded-full bg-sky-200">
                        {`
                      ${plan.priceRecurring} euro
                      per
                      ${plan.recurringPricePer?.toString()} `}
                      </li>
                    )
                  }
                  {
                    (plan.pricePlanType === PricePlan_PricePlanType.OneTime || plan.pricePlanType === PricePlan_PricePlanType.OneTimeSubscription) && (
                      <li className="p-1 px-3 text-sm text-center rounded-full bg-sky-200">
                        {`${plan.priceOneTime}
                       euro, eenmalig`}
                      </li>
                    )
                  }
                </ul>
              </div>
            </div>
          </Card>
        </div>
      );
      // eslint-disable-next-line react/jsx-no-useless-fragment
    } return <></>;
  }

  return (
    <>
      <MySubscriptionsHero />
      <div className="mx-auto relative max-w-screen-xl flex items-center justify-center w-full bg-gray-100">

        {(!loading && !productOrdersLoading) ? (
          <div className="columns-1 md:columns-2 lg:columns-3 gap-12 space-y-12 py-12 lg:w-full break-inside-avoid-column">
            {data?.dataMarket.subscriptions?.map((subscription) => (
              renderSubscription(subscription)
            ))}
            {
              productOrders?.ProductOrders?.docs?.map((order) => (renderProductOrders(order as ProductOrder)))
            }
          </div>
        ) : (
          <div className="columns-3 md:columns-2 lg:columns-3 gap-12 space-y-12 py-12 w-full">
            <div
              className="justify-start relative bg-gray-300 dark:bg-gray-700 animate-pulse h-[505px] w-full rounded-lg break-inside-avoid max-w-sm"
            />
            <div
              className="justify-start relative bg-gray-300 dark:bg-gray-700 animate-pulse h-[505px] w-full rounded-lg break-inside-avoid max-w-sm"
            />
            <div
              className="justify-start relative bg-gray-300 dark:bg-gray-700 animate-pulse h-[505px] w-full rounded-lg break-inside-avoid max-w-sm"
            />
          </div>
        )}
      </div>
    </>
  );
}

export default MySubscriptionsOverview;
