import React from 'react';
import { Navbar } from 'flowbite-react';
import Loader from './Loader';
import wecity_logo_main from '../images/wecity_logo.svg';

function LoadingPage() {
  return (
    <div className="mx-auto max-w-screen-xl bg-gray-100">
      <div className="p-3 bg-gray-100 items-center flex-col">
        <Navbar fluid rounded className="bg-gray-100">
          <Navbar.Brand href="#">
            <img src={wecity_logo_main} className="h-8" alt="WeCity Logo" />
          </Navbar.Brand>
        </Navbar>
      </div>
      <div role="status">
        <Loader />
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  );
}

export default LoadingPage;
