import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useOidcIdToken } from '@axa-fr/react-oidc';
import useDefaultDataMarketOrganization from '../hooks/useDefaultDataMarketOrganization';

function GenericSupportCard() {
  const { idTokenPayload } = useOidcIdToken();
  const defaultOrgId = useDefaultDataMarketOrganization();
  const params = useParams();

  function createSupportUrl(): string {
    let urlParams = new URLSearchParams({
      email: idTokenPayload?.email ?? '',
    });

    const paramOrgId = params?.orgId ? params.orgId : defaultOrgId;
    if (paramOrgId !== undefined) {
      urlParams = new URLSearchParams({
        ...Object.fromEntries(urlParams),
        ...{
          subject: `${paramOrgId} - DM algemene support vraag`,
          wecity_organization_id: paramOrgId,
        },
      });
    }

    if (process.env.REACT_APP_ENVIRONMENT === 'production') {
      return `https://share-eu1.hsforms.com/1IKJS08fwR267eThwS0Xe7w2borbx?${urlParams.toString()}`;
    }

    return `https://share-eu1.hsforms.com/1b-OJG4paQcGRg7P2l13qwg2dc0ht?${urlParams.toString()}`;
  }

  return (
    <div className="flex bg-white rounded-lg max-w-sm break-inside-avoid">
      <div className="p-4">
        <div className="text-base text-gray-900 ">Heeft u een vraag over abonnementsvormen of het abonneren?</div>
        <div className="text-gray-900 font-bold text-base">
          {}
          <Link target="_blank" to={createSupportUrl()}>
            Vraag het WeCity
            {' '}
            <FontAwesomeIcon
              icon={icon({ name: 'external-link', family: 'classic', style: 'regular' })}
            />
          </Link>
        </div>
      </div>
    </div>
  );
}

export default GenericSupportCard;
