import React from 'react';
import {
  Badge as FlowBiteBadge, FlowbiteColors,
} from 'flowbite-react';

function Badge({ color, children }: BadgeProps) {
  return (
    <FlowBiteBadge href="#" className="rounded-md px-2.5 py-1.5" color={color ?? 'gray'}>
      <div className="capitalize text-center text-gray-900 text-xs font-medium leading-none">
        {children}
      </div>
    </FlowBiteBadge>
  );
}

type BadgeProps = {
  color?: keyof FlowbiteColors
  children: React.ReactNode
};

export default Badge;
