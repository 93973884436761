import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useOidcIdToken } from '@axa-fr/react-oidc';
import useDefaultDataMarketOrganization from '../hooks/useDefaultDataMarketOrganization';

function ProductSupportCard({ productName, productId, planId }:ProductSupportCardProps) {
  const { idTokenPayload } = useOidcIdToken();
  const defaultOrgId = useDefaultDataMarketOrganization();
  const params = useParams();

  function createSupportUrl(): string {
    const urlParams = new URLSearchParams({
      email: idTokenPayload.email ?? '',
      subject: productName,
      wecity_organization_id: params?.orgId ? params.orgId : (defaultOrgId ?? 'onbekend'),
      dm_product_id: productId,
      dm_plan_id: planId,
    });

    if (process.env.REACT_APP_ENVIRONMENT === 'production') {
      return `https://share-eu1.hsforms.com/1jW5G0k_qSFWJ1f2TB-ROPw2borbx?${urlParams.toString()}`;
    }

    return `https://share-eu1.hsforms.com/1NfN_MoLTTkqa1ytBopXSTA2dc0ht?${urlParams.toString()}`;
  }

  return (
    <div className="flex bg-white rounded-lg max-w-sm break-inside-avoid">
      <div className="p-4">
        <div className="text-base text-gray-900 ">
          Heeft u een vraag over het product
          {' '}
          {productName}
          ?
        </div>
        <div className="text-gray-900 font-bold text-base">
          {}
          <Link target="_blank" to={createSupportUrl()}>
            Vraag het WeCity
            {' '}
            <FontAwesomeIcon
              icon={icon({ name: 'external-link', family: 'classic', style: 'regular' })}
            />
          </Link>
        </div>
      </div>
    </div>
  );
}

type ProductSupportCardProps = {
  productName: string
  productId: string
  planId: string
};

export default ProductSupportCard;
