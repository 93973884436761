import React from 'react';
import {
  useGetDataProductsQuery,
} from '../../generated/gql/types';
import UseCaseSubmissionCard from '../library/UseCaseSubmissionCard';

function UseCase() {
  const { loading } = useGetDataProductsQuery({
    fetchPolicy: 'network-only',
  });

  return (
    <div className="mx-auto relative max-w-screen-xl flex items-center justify-center w-full bg-gray-100">
      {(!loading) ? (
        <div
          className="columns-1 md:columns-2 lg:columns-3 gap-12 space-y-12 py-12 lg:w-full break-inside-avoid-column"
        >
          <UseCaseSubmissionCard />
        </div>
      ) : (
        <div className="columns-3 md:columns-2 lg:columns-3 gap-12 space-y-12 py-12 w-full">
          <div
            className="justify-start relative bg-gray-300 dark:bg-gray-700 animate-pulse h-[505px] w-full rounded-lg break-inside-avoid max-w-sm"
          />
          <div
            className="justify-start relative bg-gray-300 dark:bg-gray-700 animate-pulse h-[505px] w-full rounded-lg break-inside-avoid max-w-sm"
          />
          <div
            className="justify-start relative bg-gray-300 dark:bg-gray-700 animate-pulse h-[505px] w-full rounded-lg break-inside-avoid max-w-sm"
          />
        </div>
      )}
    </div>
  );
}

export default UseCase;
