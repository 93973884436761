import React, { useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
  Button, Select,
} from 'flowbite-react';
import { useForm } from 'react-hook-form';
import { WeCityOrganizationIdHeader } from '../../../Global';
import { syncOfferWithLocalStorage } from '../../../Helpers';
import {
  ProductUpdate_ProductVisibility_MutationInput,
  useGetGenericProductQuery,
  useUpdateGenericProductMutation,
} from '../../../generated/gql/types';
import {
  GenericOfferWizardPricePlansroute,
  GenericOfferWizardThemesRoute,
} from '../../Routes';
import FormField from '../../library/form/FormField';
import WizardStep from '../../library/form/WizardStep';
import { GenericProductForm } from '../../../productForms';
import Loader from '../../Loader';

function GenericProductVisibility() {
  const navigate = useNavigate();
  const params = useParams();
  const { data: cmsProduct, loading: cmsProductLoading } = useGetGenericProductQuery({
    context: {
      clientName: 'cms',
      headers: {
        [WeCityOrganizationIdHeader]: params.orgId,
      },
    },
    fetchPolicy: 'network-only',
    variables: {
      id: params.productId ?? '',
    },
  });

  const [updateProduct, { loading: updateLoading }] = useUpdateGenericProductMutation({
    context: {
      clientName: 'cms',
      headers: {
        [WeCityOrganizationIdHeader]: params.orgId,
      },
    },
  });
  const {
    handleSubmit,
    register,
  } = useForm<Fields>();

  useEffect(() => {
    if (cmsProduct?.Product?.id && params.productId) {
      syncOfferWithLocalStorage(params.productId, cmsProduct.Product.id);
    }
  }, [cmsProduct]);

  const onSubmit = handleSubmit((fields) => {
    if (cmsProduct?.Product?.id) {
      updateProduct({
        variables: {
          id: cmsProduct?.Product?.id,
          data: {
            productVisibility: fields.visibility as ProductUpdate_ProductVisibility_MutationInput,
          },
        },
      }).then((_) => {
        navigate(GenericOfferWizardPricePlansroute(params.orgId, params.productId, params.productType));
      });
    }
  });

  return (
    <WizardStep form={GenericProductForm} currentStep={2} onSubmit={onSubmit}>
      {!cmsProductLoading
        ? (
          <>
            <FormField fieldName="title" label="Zichtbaarheid van je product in de catalogus" appendAsterix>
              <Select
                id="visibility"
                color="info"
                className="max-w-screen-md"
                required
                {...register('visibility', { value: (cmsProduct?.Product?.productVisibility ?? ProductUpdate_ProductVisibility_MutationInput.Private) })}
              >
                <option value="private">
                  Private: Alleen jij als creator kan het product zien
                </option>
                <option value="everyone">
                  Openbaar: Iedereen kan het product zien
                </option>
                <option value="dmi_only">
                  DMI: Het product is alleen voor DMI leden te zien
                </option>
              </Select>
            </FormField>

            <div className="flex gap-x-4">
              <Link to={GenericOfferWizardThemesRoute(params.orgId, params.productId, params.productType)}>
                <Button size="lg" color="light">
                  <div className="w-full justify-center items-center gap-2 inline-flex">
                    <div className="text-xs lg:text-sm font-medium w-full">Terug</div>
                  </div>
                </Button>
              </Link>

              {!updateLoading ? (
                <Button
                  type="submit"
                  className="flex items-center justify-self-center self-start rounded-lg text-center font-medium"
                >
                  <div className="px-0.5 py-0.5">Volgende</div>
                </Button>
              ) : (
                <Button
                  disabled
                  type="button"
                  className="inline-flex opacity:50 items-center justify-self-center self-start rounded-lg text-center font-medium"
                >
                  <div className="px-0.5 py-0.5">Volgende</div>
                </Button>
              )}
            </div>
          </>
        ) : <Loader />}
    </WizardStep>
  );
}

type Fields = {
  visibility: string,
};

export default GenericProductVisibility;
