import React, { Fragment } from 'react';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function CategoriesToIcons({ categories }: CategoriesToIconsProps) {
  return (
    <>
      {categories.slice(0, 5).filter((c) => c !== '').map((c) => c?.toLowerCase()).map((category) => (
        <Fragment key={`odsicon-${category}`}>
          {(category === 'tree' || category === 'bomen') && (
            <FontAwesomeIcon
              key="tree"
              icon={icon({ name: 'tree', family: 'classic', style: 'solid' })}
              size="2x"
              className="text-primary-800"
            />
          )}
          {category === 'water' && (
            <FontAwesomeIcon
              key="water"
              icon={icon({ name: 'droplet', family: 'classic', style: 'solid' })}
              size="2x"
              className="text-primary-800"
            />
          )}
          {(category === 'car' || category === 'auto') && (
            <FontAwesomeIcon
              key="car"
              icon={icon({ name: 'car', family: 'classic', style: 'solid' })}
              size="2x"
              className="text-primary-800"
            />
          )}
          {category === 'truck' && (
            <FontAwesomeIcon
              key="truck"
              icon={icon({ name: 'truck', family: 'classic', style: 'solid' })}
              size="2x"
              className="text-primary-800"
            />
          )}
          {(category === 'bicycle' || category === 'bike' || category === 'fiets') && (
            <FontAwesomeIcon
              key="bike"
              icon={icon({ name: 'bicycle', family: 'classic', style: 'solid' })}
              size="2x"
              className="text-primary-800"
            />
          )}
          {(category === 'weather' || category === 'climate') && (
            <FontAwesomeIcon
              key="weather"
              icon={icon({ name: 'sun', family: 'classic', style: 'solid' })}
              size="2x"
              className="text-primary-800"
            />
          )}
          {(category === 'map') && (
            <FontAwesomeIcon
              icon={icon({ name: 'map', family: 'classic', style: 'solid' })}
              size="2x"
              className="text-primary-800"
            />
          )}
          {(category === 'gps') && (
            <FontAwesomeIcon
              key="gps"
              icon={icon({ name: 'location-dot', family: 'classic', style: 'solid' })}
              size="2x"
              className="text-primary-800"
            />
          )}
          {(category === 'energy') && (
            <FontAwesomeIcon
              key="energy"
              icon={icon({ name: 'bolt', family: 'classic', style: 'solid' })}
              size="2x"
              className="text-primary-800"
            />
          )}
          {(category === 'road') && (
            <FontAwesomeIcon
              key="road"
              icon={icon({ name: 'road', family: 'classic', style: 'solid' })}
              size="2x"
              className="text-primary-800"
            />
          )}
          {(category === 'border') && (
            <FontAwesomeIcon
              key="border"
              icon={icon({ name: 'map-signs', family: 'classic', style: 'solid' })}
              size="2x"
              className="text-primary-800"
            />
          )}
          {(category === 'parking') && (
            <FontAwesomeIcon
              key="parking"
              icon={icon({ name: 'parking', family: 'classic', style: 'solid' })}
              size="2x"
              className="text-primary-800"
            />
          )}
          {(category === 'construction') && (
            <FontAwesomeIcon
              key="construction"
              icon={icon({ name: 'user-helmet-safety', family: 'classic', style: 'solid' })}
              size="2x"
              className="text-primary-800"
            />
          )}
          {(category === 'nature') && (
            <FontAwesomeIcon
              key="nature"
              icon={icon({ name: 'squirrel', family: 'classic', style: 'solid' })}
              size="2x"
              className="text-primary-800"
            />
          )}
          {(category === 'environment') && (
            <FontAwesomeIcon
              key="environment"
              icon={icon({ name: 'leaf', family: 'classic', style: 'solid' })}
              size="2x"
              className="text-primary-800"
            />
          )}
          {(category === 'soil') && (
            <FontAwesomeIcon
              key="soil"
              icon={icon({ name: 'shovel', family: 'classic', style: 'solid' })}
              size="2x"
              className="text-primary-800"
            />
          )}
          {(category === 'accessibility') && (
            <FontAwesomeIcon
              key="accessibility"
              icon={icon({ name: 'universal-access', family: 'classic', style: 'solid' })}
              size="2x"
              className="text-primary-800"
            />
          )}
        </Fragment>
      ))}
    </>
  );
}

type CategoriesToIconsProps = {
  categories: string[]
};

export default CategoriesToIcons;
