import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Badge, Button } from 'flowbite-react';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useOidcIdToken } from '@axa-fr/react-oidc';
import Card from '../library/Card';
import {
  DataProduct,
  GetDataProductsQuery,
  GetGenericProductQuery,
  PricePlan,
  PricePlan_PricePlanType,
  PricePlan_RecurringPricePer,
  Product,
  ProductOrder_PricePlanRelationshipInput,
  ProductOrder_ProductRelationshipInput,
  useCreateProductOrderMutation,
  useGetDataProductsQuery,
  useGetGenericProductQuery,
  useGetProductOrdersQuery,
} from '../../generated/gql/types';
import GenericSupportCard from '../library/GenericSupportCard';
import {
  timeFrameToString, PricePlanTypeToString, UnifiedProduct, unifyDataProduct, unifyGenericProduct,
} from '../../Helpers';
import DataProductCard from '../library/DataProductCard';
import ProductTabGroup from '../library/ProductTabGroup';
import PriceplanCard from '../library/PriceplanCard';
import { MySubscriptionsOverviewRoute } from '../Routes';
import { ProductType, WeCityOrganizationIdHeader } from '../../Global';
import defaultOrgId from './DefaultOrgId';
import DataProductTabGroup from '../library/DataProductTabGroup';
import ProductCard from '../library/ProductCard';

interface ICatalogProductProps {
  preview?: boolean;
}

function CatalogProduct({ preview = false }: ICatalogProductProps) {
  const params = useParams();
  const orgId = defaultOrgId();
  const { idTokenPayload } = useOidcIdToken();
  const navigate = useNavigate();
  const [product, setProduct] = useState<UnifiedProduct | undefined>(undefined);
  const [pricePlans, setPricePlans] = useState<PricePlan[]>([]);

  const { data, loading } = params.productType !== 'dataproduct' ? useGetGenericProductQuery({
    context: {
      clientName: 'cms',
      headers: {
        [WeCityOrganizationIdHeader]: params.orgId,
      },
    },
    fetchPolicy: 'network-only',
    variables: {
      id: params.productId || '',
    },
  }) : useGetDataProductsQuery({
    fetchPolicy: 'network-only',
  });

  const [createProductOrder] = useCreateProductOrderMutation({
    fetchPolicy: 'network-only',
  });

  const { data: productOrders } = useGetProductOrdersQuery({
    fetchPolicy: 'network-only',
    context: {
      clientName: 'cms',
      headers: {
        [WeCityOrganizationIdHeader]: params.orgId ?? orgId,
      },
    },
  });

  const alreadyPurchased = (pricePlanId: string) => {
    if (productOrders?.ProductOrders?.docs) {
      return productOrders.ProductOrders.docs.some((order) => order?.product?.value?.id === params.productId && order?.pricePlan?.value?.id === pricePlanId);
    }
    return false;
  };

  const handleCreateProductOrder = (pricePlanId: string) => {
    createProductOrder({
      context: {
        clientName: 'cms',
        headers: {
          [WeCityOrganizationIdHeader]: params.orgId ?? orgId,
        },
      },
      variables: {
        input: {
          id: crypto.randomUUID() as string,
          contactEmail: idTokenPayload.email,
          active: false,
          product: {
            relationTo: 'products',
            value: params.productId,
          } as ProductOrder_ProductRelationshipInput,
          pricePlan: {
            relationTo: 'price_plans',
            value: pricePlanId,
          } as ProductOrder_PricePlanRelationshipInput,
        },
      },
    }).then((_) => {
      navigate(MySubscriptionsOverviewRoute(params.orgId ?? orgId));
    });
  };

  useEffect(() => {
    if (!loading && data) {
      if (params.productType === 'dataproduct') {
        const dataProduct = data as GetDataProductsQuery;
        if (!loading && (dataProduct?.dataMarket.products)) {
          const entity = dataProduct?.dataMarket.products?.find((p) => p.id === params.productId) as DataProduct;
          setProduct(unifyDataProduct(entity));
        }
      } else if (params.productType && params.productType !== 'dataproduct') {
        const genericProduct = data as GetGenericProductQuery;

        setPricePlans(genericProduct?.Product?.pricePlans?.map((plan) => plan.value as PricePlan) ?? []);
        if (!loading && genericProduct?.Product) {
          setProduct(unifyGenericProduct(genericProduct.Product as Product));
        }
      }
    }
  }, [loading, data]);

  const [showConditions, setShowConditions] = useState(false);

  const getOdpsFromDataProduct = (dataProduct: DataProduct) => JSON.parse(dataProduct.openDataProductSpecification);

  function getButtonText(planId: string) {
    if (alreadyPurchased(planId)) return 'Je hebt dit product al gekocht!';
    if (!idTokenPayload) return 'Login vereist';
    return 'Product kopen';
  }

  function renderDataProductPricePlanCards() {
    // function should be changed when data products use the same price plans as generic products
    const dataProduct = product?.originalProduct as DataProduct;
    const odps = JSON.parse(dataProduct?.openDataProductSpecification);
    return (
      (odps.product?.pricingPlans?.en?.map((pricingPlan: any) => (
        <div key={`pricplan-${pricingPlan.name}`} className="flex lg:block justify-center">
          <PriceplanCard pricingPlan={pricingPlan} productName={odps?.product?.en?.name ?? 'Plan'} productId={dataProduct.id} />
        </div>
      )))
    );
  }

  function renderGenericProductPricePlanCards() {
    return (
      <div>
        {pricePlans.map((pricePlan) => (
          <Card
            key={`pricePlanCard-${pricePlan.id}`}
            header={(
              <div
                className="hidden pt-0 lg:inline-flex w-full h-36 bg-blue-400 justify-center items-center gap-6 rounded-t-lg lg:text-2xl"
              >
                <FontAwesomeIcon
                  icon={icon({ name: 'coins', family: 'classic', style: 'solid' })}
                  size="2x"
                  className="text-primary-800"
                />
              </div>
            )}
            backgroundColor="white"
            isMini={false}
          >
            <div className="flex w-full">
              <div className="flex-col grow p-3 lg:p-5 justify-between w-full items-center inline-flex gap-3">
                <div className="w-full h-5 justify-start items-start gap-2 inline-flex mb-2">
                  <Badge key="inprogress" color="purple">{PricePlanTypeToString(pricePlan.pricePlanType as PricePlan_PricePlanType)}</Badge>
                </div>

                <div className="flex-col justify-start items-start inline-flex w-full">
                  <div className="text-left w-full text-gray-900 text-base lg:text-2xl font-bold">
                    {pricePlan.name}
                  </div>
                  <div
                    className="basis-0 text-gray-500 text-base font-normal leading-normal"
                  >
                    <p className="line-clamp-3 mb-2 text-sm lg:text-base">
                      {pricePlan.description}
                    </p>
                  </div>
                </div>
                <div className="w-full bg-gray-200 p-3 py-5 rounded-xl flex flex-col">
                  {(pricePlan.pricePlanType === PricePlan_PricePlanType.OneTime || pricePlan.pricePlanType === PricePlan_PricePlanType.OneTimeSubscription) && (
                    <div>
                      <span className="text-gray-900 text-xl font-bold">
                        {`€${pricePlan.priceOneTime} `}
                      </span>
                      eenmalig
                    </div>
                  )}
                  {pricePlan.pricePlanType === PricePlan_PricePlanType.OneTimeSubscription && (
                    <div className="h-[2px] w-full bg-gray-400 my-3" />)}
                  {(pricePlan.pricePlanType === PricePlan_PricePlanType.Subscription || pricePlan.pricePlanType === PricePlan_PricePlanType.OneTimeSubscription) && (
                    <div className="flex flex-col">
                      <div className="flex">
                        <div>
                          <span className="text-gray-900 text-xl font-bold">
                            {`€${pricePlan.priceRecurring} `}
                          </span>
                          {'per '}
                          {timeFrameToString(pricePlan.recurringPricePer as PricePlan_RecurringPricePer)}
                        </div>
                        <Button type="button" className="ml-auto my-auto text-sm p-1 px-3" size="2xs" color="gray" onClick={() => setShowConditions(!showConditions)}>
                          Voorwaarden
                          <FontAwesomeIcon
                            className="ml-2"
                            icon={icon({ name: 'chevron-down', family: 'classic', style: 'regular' })}
                            size="lg"
                          />
                        </Button>
                      </div>
                      {showConditions && (
                        <div className="w-full mt-2 text-sm">
                          Voor dit prijsplan gelden de volgende voorwaarden omtrend afname en betaling:
                          <div className="italic">{` - Er geldt een minimale afnameperiode van 1 ${timeFrameToString(pricePlan.minimumSubscriptionTime || '').toLowerCase()}.`}</div>
                          <div className="italic">{` - De facturatie voor dit plan gaat per ${timeFrameToString(pricePlan.minimumSubscriptionTime || '').toLocaleLowerCase()}.`}</div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
                {!preview && <Button disabled={!idTokenPayload || alreadyPurchased(pricePlan.id)} onClick={() => handleCreateProductOrder(pricePlan.id)}>{getButtonText(pricePlan.id)}</Button>}
              </div>
            </div>
          </Card>
        ))}
      </div>
    );
  }

  const renderGenericProductDetails = () => {
    if (product) {
      return (
        <>
          <ProductCard
            title={product.name}
            description={product.description}
            themes={product.themes}
            productType={product?.productType.value as ProductType}
            providerName={product.organization}
          />
          <ProductTabGroup product={product} />
        </>
      );
    }
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <></>;
  };

  const renderDataProductDetails = () => {
    if (product) {
      return (
        <>
          <DataProductCard isMini odps={getOdpsFromDataProduct(product.originalProduct as DataProduct)} />
          <DataProductTabGroup odps={getOdpsFromDataProduct(product.originalProduct as DataProduct)} />
        </>
      );
    }
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <></>;
  };

  return (
    <div className={`mx-auto relative max-w-screen-xl flex items-center justify-center w-full bg-gray-100 ${!preview ? 'py-6' : 'p-6 rounded-lg shadow-inner'}`}>
      {!loading && product ? (
        <div className="flex-col w-full justify-center">
          <div className="flex-col space-y-6 lg:flex lg:flex-row lg:space-x-6 lg:space-y-0">
            {product.productType.value === 'dataproduct' ? renderDataProductDetails() : renderGenericProductDetails()}
          </div>
          <div className="relative flex py-5 items-center">
            <div className="flex-grow border-t border-gray-300" />
            <span className="flex-shrink mx-4 text-gray-600 text-lg items-center font-semibold">Abonnementsvormen</span>
            <div className="flex-grow border-t border-gray-300" />
          </div>

          <div
            className="columns-1 md:columns-2 lg:columns-3 gap-12 space-y-6 lg:w-full break-inside-avoid-column"
          >
            {params.productType === 'dataproduct' ? renderDataProductPricePlanCards() : renderGenericProductPricePlanCards()}

            {!preview && <GenericSupportCard />}
          </div>
        </div>
      ) : (
        <div className="flex-col w-full justify-center">
          <div className="flex-col space-y-6 lg:flex lg:flex-row lg:space-x-6 lg:space-y-0">
            <div className="w-[350px] relative bg-gray-300 dark:bg-gray-700 animate-pulse h-[300px] rounded-lg" />
            <div
              className="flex grow rounded-lg w-full justify-start relative bg-gray-300 dark:bg-gray-700 animate-pulse"
            />
          </div>
          <div className="columns-3 md:columns-2 lg:columns-3 gap-12 space-y-12 py-6 w-full">
            <div
              className="justify-start relative bg-gray-300 dark:bg-gray-700 animate-pulse h-[384px] w-full rounded-lg break-inside-avoid max-w-sm"
            />
            <div
              className="justify-start relative bg-gray-300 dark:bg-gray-700 animate-pulse h-[384px] w-full rounded-lg break-inside-avoid max-w-sm"
            />
            <div
              className="justify-start relative bg-gray-300 dark:bg-gray-700 animate-pulse h-[384px] w-full rounded-lg break-inside-avoid max-w-sm"
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default CatalogProduct;
