import React from 'react';
import {
  Card as FlowBiteCard, CustomFlowbiteTheme,
} from 'flowbite-react';

function Card({
  header, children, backgroundColor, isMini,
}: CardProps) {
  const customTheme: CustomFlowbiteTheme['card'] = {
    root: {
      base: backgroundColor === 'gray' ? 'bg-gray-400 shadow-none' : 'bg-white shadow-none',
      children: backgroundColor === 'gray' ? 'bg-gray-400 rounded-lg' : 'bg-white rounded-lg',
    },
  };

  if (isMini) {
    return (
      <FlowBiteCard
        theme={customTheme}
        className="lg:max-w-xs max-w-sm max-h-[400px] rounded-lg break-inside-avoid"
        renderImage={() => header}
      >
        <div className="flex-col justify-between items-center inline-flex">
          {children}
        </div>
      </FlowBiteCard>
    );
  }

  return (
    <FlowBiteCard
      theme={customTheme}
      className="max-w-sm rounded-lg break-inside-avoid"
      renderImage={() => header}
    >
      <div className="flex-col justify-between items-center inline-flex w-full">
        {children}
      </div>
    </FlowBiteCard>
  );
}

type CardProps = {
  header: React.JSX.Element
  children: React.ReactNode
  backgroundColor?: 'gray' | 'white'
  isMini?: boolean
};

export default Card;
