import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Table } from 'flowbite-react';
import { Link, useParams } from 'react-router-dom';
import Badge from './Badge';
import Card from './Card';
import CategoriesToIcons from './CategoriesToIcons';
import InfoButton from './InfoButton';
import TagList from './TagList';
import { cardHeaderColors, getIconForTheme, UnifiedProduct } from '../../Helpers';
import { DataProductState } from '../../generated/gql/types';
import { GenericOfferWizardYourProductRoute, OfferWizardAutoFillOdpsRoute } from '../Routes';

type ProductProps = {
  product: UnifiedProduct,
  productRoute?: string,
  isMini?: boolean,
  deleteProduct: (productType: string, productId: string) => void
};

function UnifiedProductOfferCard({
  productRoute, product, isMini, deleteProduct,
}: ProductProps) {
  const params = useParams();

  const productTypeIcon = () => {
    switch (product.productType.value) {
      case 'dataproduct': return (
        <FontAwesomeIcon
          key="dataproduct"
          icon={icon({ name: 'diagram-project', family: 'classic', style: 'solid' })}
          size="2x"
          className="text-primary-800"
        />
      );

      case 'consultancy': return (
        <FontAwesomeIcon
          key="consultancy"
          icon={icon({ name: 'user-tie', family: 'classic', style: 'solid' })}
          size="2x"
          className="text-primary-800"
        />
      );
      case 'application': return (
        <FontAwesomeIcon
          key="application"
          icon={icon({ name: 'arrow-pointer', family: 'classic', style: 'solid' })}
          size="2x"
          className="text-primary-800"
        />
      );
      case 'hardware': return (
        <FontAwesomeIcon
          key="hardware"
          icon={icon({ name: 'computer', family: 'classic', style: 'solid' })}
          size="2x"
          className="text-primary-800"
        />
      );
      default
        // eslint-disable-next-line react/jsx-no-useless-fragment
        : return <></>;
    }
  };

  const editLinkTo = () => {
    if (product.productType.value === 'dataproduct') {
      return OfferWizardAutoFillOdpsRoute(params.orgId, product?.id);
    }

    return GenericOfferWizardYourProductRoute(params.orgId, product?.id, product.productType.value);
  };

  function renderHeader() {
    return (
      <div
        className={`${cardHeaderColors(product.productType.value)} flex flex-col pt-0 w-full h-36 gap-6 rounded-t-lg`}
      >
        <div className="lg:text-2xl lg:inline-flex my-auto gap-2 items-center justify-center">
          {
            productTypeIcon()
          }
          <CategoriesToIcons categories={product.themes.map((t) => getIconForTheme(t) || '')} />
        </div>
      </div>
    );
  }

  function renderDetailsButton() {
    if (!productRoute) {
      // eslint-disable-next-line react/jsx-no-useless-fragment
      return <></>;
    }

    return (
      <div className="flex pt-2 justify-center lg:justify-end w-full">
        <Link to={productRoute}>
          <InfoButton>
            <div className="text-xs lg:text-sm font-medium">Meer informatie</div>
            <FontAwesomeIcon
              icon={icon({ name: 'arrow-right', family: 'classic', style: 'solid' })}
              size="lg"
              className="text-white"
            />
          </InfoButton>
        </Link>
      </div>
    );
  }

  function addStateBadge(): React.JSX.Element | string {
    if (product?.reviewState === DataProductState.Draft) {
      return (<Badge key={DataProductState.Draft} color="green">Voorlopige versie</Badge>);
    } if (product?.reviewState === DataProductState.Pending) {
      return (<Badge key={DataProductState.Pending} color="warning">In behandeling</Badge>);
    } if (product?.reviewState === DataProductState.Amend) {
      return (<Badge key={DataProductState.Amend} color="failure">Aanpassing vereist</Badge>);
    }

    return (<Badge key="live" color="info">Actief</Badge>);
  }

  return (
    <Card
      header={renderHeader()}
      backgroundColor="white"
      isMini={isMini}
    >
      <div className="flex w-full">

        <div
          className={`${cardHeaderColors(product.productType.value)} flex-col p-5 rounded-tl-lg rounded-bl-lg lg:hidden`}
        >
          <div className="flex h-full items-center">
            {(product.themes ?? []).length > 0 && (
              <div className="flex-col space-y-4 text-xs lg:text-base">
                <CategoriesToIcons categories={product.themes ?? []} />
              </div>
            )}
          </div>
        </div>

        <div className="flex-col grow p-3 lg:p-5 justify-between items-center inline-flex">
          {(product.themes ?? []).length > 0 && (
            <div className="w-full justify-start items-start gap-2 flex flex-wrap mb-2">
              {addStateBadge()}
              {(product.themes ?? []).map((category: string) => (
                <Badge key={category}>{category}</Badge>
              ))}
            </div>
          )}

          <div className="flex-col justify-start items-start inline-flex w-full">
            <div className="text-left w-full text-gray-900 text-base lg:text-2xl font-bold">
              {product.name}
            </div>
            {!isMini && (
              <div
                className="basis-0 text-gray-500 text-base font-normal leading-normal"
              >
                <p className="line-clamp-3 mb-2 text-sm lg:text-base">{product.description}</p>
              </div>
            )}
          </div>
          <div className="pb-1">
            <TagList tags={product.tags} limit={5} />
          </div>
          <div className="w-full">
            <Table className="w-full">
              <Table.Body className="overflow-x-hidden text-left w-full items-start">
                <Table.Row className="border-t border-solid border-gray-200 ">
                  <Table.Cell className="text-gray-400 p-0 pr-1 py-1 inline-flex space-x-2">
                    <div className="text-gray-400">
                      Aanbieder:
                    </div>
                    <div className="text-gray-800 font-medium whitespace-nowrap">
                      {product?.owner ?? 'unknown'}
                    </div>
                  </Table.Cell>
                  <Table.Cell className="p-1 px-2 inline-flex  space-x-2">
                    <div className="text-gray-400">
                      Producttype:
                    </div>
                    <div className="text-gray-800 font-medium whitespace-nowrap">
                      {product.productType.label}
                    </div>
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </div>
          <div className="flex pt-2 justify-between items-center w-full gap-x-4">
            <button
              onClick={() => deleteProduct(product.productType.value, product.id)}
              type="button"
              className="text-red-700"
            >
              Verwijder aanbieding
            </button>
            {((!isMini || productRoute) && product?.reviewState === 'ACCEPTED') ? (
              renderDetailsButton()
            ) : (
              <div className="flex pt-2 justify-center lg:justify-end">
                <Link to={editLinkTo()}>
                  <InfoButton>
                    <div className="text-xs lg:text-sm font-medium">Bewerken</div>
                    <FontAwesomeIcon
                      icon={icon({ name: 'arrow-right', family: 'classic', style: 'solid' })}
                      size="lg"
                      className="text-white"
                    />
                  </InfoButton>
                </Link>
              </div>
            )}

          </div>
        </div>
      </div>
    </Card>
  );
}

export default UnifiedProductOfferCard;
