import React from 'react';
import { Tooltip } from 'flowbite-react';

function CopyToClipboardButton({
  content, clipboardData, tooltipMessage,
}: CopyToClipboardButtonProps) {
  return (
    <Tooltip
      content={tooltipMessage}
      trigger="click"
    >
      <button
        type="button"
        onClick={() => navigator.clipboard.writeText(clipboardData)}
      >
        {content}
      </button>
    </Tooltip>
  );
}

type CopyToClipboardButtonProps = {
  clipboardData: string
  content: JSX.Element | string
  tooltipMessage: string
};

export default CopyToClipboardButton;
